import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import { useDocumentA } from "../../hooks/useDocumentA";
import { useFirestore } from "../../hooks/useFirestore";
import HeadingH3Centered from "../../components/HeadingH3Centered";
import { BUTTON_SUBMIT_STYLE_1, LINK_STYLE_3 } from "../../components/Constants";

// yF
// riF yoT noF xiF xoF 03: iOpen oYes => yT Collaboration
//  =iT stop->07
//  =iF stop->11

export default function GoToStage03() {
  // I am the user.uid 
  // in my contact.o is the uID of the other one
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  // oUID is uID of the other one
  const { oUID } = useParams()
  // documentA is the account of the other one
  const { errorA, documentA } = useDocumentA('USERS', oUID)
  const { updateDocument, response } = useFirestore('USERS')
  const navigate = useNavigate()

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
  if (errorA) {
    return <div className="error">{errorA}</div>
  }
  if (!documentA) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // update my account
    const updatedArrMy = document.C.map((contact) => 
      contact.o === oUID ? { ...contact, ...{
        y:true,
        ri:false,
        yo:true,
        no:false,
        xi:false,
        xo:false
      }} : contact
    )

    const updatesMy = { 
      C: updatedArrMy // C Contacts array
    }

    // Other persons acc is documentA
    const updatedArrOther = documentA.C.map((contact) => 
      contact.o === user.uid ? { ...contact, ...{
        y:true,
        ri:false,
        yo:true,
        no:false,
        xi:false,
        xo:false
      }} : contact
    )

    const updatesOther = { 
      C: updatedArrOther // C Contacts array
    }

    // save updatesMy to my account
    await updateDocument(user.uid, updatesMy)
    // save updatesOther to other account
    await updateDocument(oUID, updatesOther)
    if (!response.error) {
      navigate('/profile')
    }
  }

  return (
    <>
      <div className="py-5">
        <div className="flex justify-center gap-3 font-mono font-bold">
          <HeadingH3Centered heading={documentA.d} />
          <HeadingH3Centered heading={documentA.e} />
        </div>
        <div className="font-mono">
          <HeadingH3Centered heading={"sends a request"} />
        </div>
      </div>

      <div className="flex justify-center">
        <form onSubmit={handleSubmit}>
          <div>
            <button type="submit" className={BUTTON_SUBMIT_STYLE_1} >
              Accept
            </button>
          </div>
        </form>
      </div>

      <div className="justify-center text-center pt-3">
        <Link to={`/profile/`} className={LINK_STYLE_3}>
          Cancel
        </Link>
      </div>
    </>
  )
}