import { useState, useEffect } from 'react'
import { auth, db } from '../firebase/config'
import { arrayUnion, doc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore"
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (email, password, displayName) => {
    setError(null)
    setIsPending(true)
  
    try {
      // signup
      const res = await createUserWithEmailAndPassword(auth, email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

      // add display name (AND PHOTO_URL [not yet implemented]) to user
      await updateProfile( res.user, { displayName })

      // update REGISTRY
      // There the document Anmeldung is a list of basic info in string form
      const anmeldungenRef = doc(db, 'REGISTRY', "Anmeldungen")
      const anmeldungenPayload = {
        N: arrayUnion(res.user.uid +" # "+email+" # "+displayName+" # "+Date.now() )
      }
      await updateDoc(anmeldungenRef, anmeldungenPayload) 

      // create a user document
      // We need q1-q9 T/F for diverse Quotas
      // And f1-f9 T/F for various Führung roles
      await setDoc(doc(db, 'USERS', res.user.uid), { 
        uID:res.user.uid, // firebase user id
        e:email, // main email 
        d:displayName, // as in firebase displayName
        t:serverTimestamp(), // created at
        o:true, // online status
        f1:false, // fuehrungs rolle 1
        f3:false, // fuehrungs rolle 3
        f5:false, // fuehrungs rolle 5
        f7:false, // fuehrungs rolle 7
        f9:false, // fuehrungs rolle 9
        qtK:25, // quota for Ks 
        qtk:0, // actual blocks created by the user
        qtJ:10, // quota for Js how many I can initiate
        qtj:0, // actual projects intiated by the user  
        qtWG:10, // quota for WG (People in a WG can be mine or others colleagues)
        qtC:20, // quota for Colleagues; actual number is calculated
        // len of C, then check i T/F I pinged, and y T/F collaboration exists
        qtCi:10, // quota, how many pings from me allowed qtCi < qtC
        C:[], // list of my colleagues 
        I:[], // list of invites for participating in a project
        J:[], // list of projects i am participating
      })

      // create a EMAILuID and register the user with email
      await setDoc(doc(db, 'EMAILuID', res.user.email), { 
        uID:res.user.uid, // firebase user id
        e:email, // main email 
        d:displayName, // as in firebase displayName
        t:serverTimestamp(), // created at
      })
      
      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}