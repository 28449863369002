import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useDocumentA } from "../../hooks/useDocumentA" 
import { useFirestoreA } from "../../hooks/useFirestoreA"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import { useEffect, useState } from "react"
import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import xForm from "../../hooks/xForm"

export default function EditBlockTitle() {
  const { user } = useAuthContext()
  const { jID, kID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const { updateDocument, response } = useFirestore('Projects') 
  const { errorA, documentA } = useDocumentA('KNM', kID)
  const { updateDocumentA, responseA } = useFirestoreA('KNM') 
  const navigate = useNavigate()
  const notify = () => toast("Updated!")
  const { xFormXaZ } = xForm()

  const [meInWG, setMeInWG] = useState({})
  const [a, setA] = useState('') // input from form block title
  // documentA is from KNM with kID, a is title field
  useEffect(() => {
    if (documentA && documentA.a){ 
      setA(documentA.a)
    }
    if (document && document.WG){ 
      let result = document.WG.filter(obj => {
        return obj.oUID === user.uid
      })
      setMeInWG(result[0])
    }
  }, [document, documentA, user.uid])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  if (errorA) {return <div className={ERROR_STYLE_1}>{errorA}</div>}
  if (!documentA) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/projectoverview/${jID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // document is project doc with jID
    // update the document.KNM list with the new val of a
    // for the block there with kID
    const updatedDocKNM = document.KNM.map(blk => 
      blk.kID === kID ? {
        ...blk, ...{
          a:a
        }
      } : blk
    )
    //
    const updatesDoc = {
      KNM: updatedDocKNM
    }

    await updateDocument(jID, updatesDoc)

    //update the a field of KNM block i.e. documentA
    const updatesDocA = {
      a // the field a, value in the same var
    }
    await updateDocumentA(kID, updatesDocA)

    if (!response.error && !responseA.error) {
      notify()
      navigate(`/projectoverview/${jID}`)
    }

  }

  return (
    <>
      {// You are not allowed!
        (documentA.c !== user.uid && 
        !meInWG.r7) && 
        <>
          You are not allowed!
        </>
      }
      {// You are allowed!
        (document.i === user.uid ||
          meInWG.r7) && 
        <>
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
                Edit Block Title
              </div>
            </div>

            <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <div className='mt-2'>
                    <div className='font-body font-medium tracking-wide leading-6 text-slate-900'
                        dangerouslySetInnerHTML={{ __html: xFormXaZ(a) }} />
                  </div>
                  <div className="mt-2">
                    <input
                      id="editA"
                      name="editA"
                      type="text"
                      onChange={(e) => setA(e.target.value)} 
                      value={a}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-abhi-red sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="flex gap-3">
                  <button 
                    type='button'
                    onClick={handleCancel}
                    className={BUTTON_CANCEL_STYLE_1}
                  >
                    Cancel
                  </button>
                  <button 
                    type='submit'
                    className={BUTTON_SUBMIT_STYLE_1}
                  >
                    Update
                  </button>
                </div>
                <div>
                  {error && <div>{error}</div>}
                </div>
              </form>

              <p className="text-center font-mono text-xs text-gray-500">
                Use with caution. This will change the title of the block.
                It could be that some of the participants may be confused!
              </p>

            </div>
          </div>
        </>
      }
    </>
  )
}