import { useEffect, useState } from 'react';
import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, LOADING_STYLE_1 } from '../../components/Constants';
import { useDocument } from '../../hooks/useDocument'
import { useNavigate, useParams } from 'react-router-dom';
import { useFirestore } from '../../hooks/useFirestore';
import HeadingH3Centered from '../../components/HeadingH3Centered';
import { useDocumentA } from '../../hooks/useDocumentA';

// create a Node within the block kID (in project jID) 
// a node is created ONLY within a project/block 
// by those who have r5/admin role (editors r3 work only on given nodes)
// all nodes with i:F are visible if the block is visible
// N:[] is the array within block kID in KNM
// the node object is pushed to this array

// nID unique node ID 
// t: is last update
// q: for sequencing
// h: true as it is pradhAna node
// m: true if left panel
// i: false if not individual / personal
// g: Yx / Yy / Yb types or Genus of node
// x: MAIN text HK
// y: Main text in eng mixed
// b: main bib title
// pID and uID are not needed here

export default function ImportNodehTmTiF2() {
  const { jID, jA, kID, impKID, impNID } = useParams()
  // where the node is to be added
  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 
  const { errorA, documentA } = useDocumentA('KNM', impKID)
  const navigate = useNavigate()

  const [hTmFiFnID, sethTmFiFnID] = useState('')
  
    useEffect(() => {
      if (documentA && documentA.N){ 
        let result = documentA.N.filter(nd => nd.h && !nd.m && nd.pID === impNID)
          if (result.length === 1) {
            sethTmFiFnID(result[0].nID)
          }
        }
      }, [documentA, impNID])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  if (errorA) {return <div className={ERROR_STYLE_1}>{errorA}</div>}
  if (!documentA) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  
  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/blockoverview/${jID}/${jA}/${kID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // first get the impJKNID
    // then get the array of the corresponding nodes
    // push this to the N arr of the present block
    const impArr = documentA.N
      // (nd.h && nd.m && nd.nID === nID)
      // hTmT node
      .filter(nd => (nd.h && nd.m && nd.nID === impNID) || 
      // (!nd.h && nd.m && nd.pID === nID) for both iF and iT
      (!nd.h && nd.m && nd.pID === impNID) ||
      // hFmT children both iF and iT
      // hTmF of hTmT
      // (nd.h && !nd.m && nd.pID === nID)
      (nd.h && !nd.m && nd.pID === impNID) ||
      // now the children of hTmF
      // (!nd.h && !nd.m && nd.pID === hTmFiFnID) for both iF and iT
      (!nd.h && !nd.m && nd.pID === hTmFiFnID) 
    )
        
    const updates = { 
      // here push the new array of nodes
      N: document.N.concat(impArr) 
      // N  array
    }
    // update the block
    await updateDocument(kID, updates)

    if (!response.error) {
      navigate(`/blockoverview/${jID}/${jA}/${kID}`)
    }
  }

  return (
    <>
      <div className="pt-5">
        <div className="font-mono font-bold">
          <HeadingH3Centered heading={"Import this a node"} />
        </div>
      </div>

      <div className="pt-5">
        <div className="font-mono font-bold">
          {impKID}/{impNID}
        </div>
      </div>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
        
            <div className="flex gap-3">
              <button 
                type='button'
                onClick={handleCancel}
                className={BUTTON_CANCEL_STYLE_1}
              >
                Cancel
              </button>
              <button 
                type='submit'
                className={BUTTON_SUBMIT_STYLE_1}
              >
                Import
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
