import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import { useDocument } from '../hooks/useDocument'
import { Link } from 'react-router-dom'
import { ExitIcon, HomeIcon, PersonIcon } from '@radix-ui/react-icons'
import { LINK_STYLE_1 } from './Constants'

export default function NavbarUser() {
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  return (
    <>
      <div className="grid grid-cols-2 bg-white font-body sticky top-0 z-50">
        <div className="flex justify-start">
          <Link to="/" className={LINK_STYLE_1.concat('ml-4 mr-2 pt-1')} >
            <HomeIcon />
          </Link>
          <Link to="/readings" className={LINK_STYLE_1.concat('mr-2')}>
            readings
          </Link>
        </div>
              
        <div className="flex justify-end">
          <Link to="/workspace" className={LINK_STYLE_1.concat('mr-2 italic')}>
            workspace
          </Link>
          <Link to="/profile" className={LINK_STYLE_1.concat('mr-2 pt-1')}>
            <PersonIcon />
          </Link>
          {user && 
            <button className={LINK_STYLE_1.concat('mr-4')} onClick={logout}>
              <ExitIcon />
            </button>
          }
        </div>
              
      </div>
    </>
  )
}
