export default function HeadingH1Centered({heading}) {
  
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
            {heading}
          </div>
        </div>
      </div>
    </>
  )
}