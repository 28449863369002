import { useState, useEffect } from "react"
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"

import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, INPUT_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import { useNavigate, useParams } from "react-router-dom"
import NavbarProjectBlock from "../../components/NavbarProjectBlock"
import HeadingH3Centered from "../../components/HeadingH3Centered"

// Edit the node of type Yx
// x main sanskrit text hk
export default function EditNodehTmTiFq() {
  const { jID, jA, kID, nID } = useParams()

  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 

  const [q, setQ] = useState('')
  const [HMiNode, setHMiNode] = useState({})
  const [HmiNode, setHmiNode] = useState({}) // corresponding Hmi node
  const navigate = useNavigate()

  useEffect(() => {
    if (document){
      const HMiNodeArr = document.N.filter(nde => nde.nID === nID && nde.h && nde.m && !nde.i)
      if (HMiNodeArr.length > 0) {
        setHMiNode(HMiNodeArr[0])
        setQ(HMiNodeArr[0].q)
      }
      // corresponding Hmi node
      const HmiNodeArr = document.N.filter(nde => nde.pID === nID && nde.h && !nde.m && !nde.i)
      if (HmiNodeArr.length > 0) {
        setHmiNode(HmiNodeArr[0])
      }
    }
  }, [document, nID])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/blockoverview/${jID}/${jA}/${kID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedN = document.N.map((nde) => 
    nde.nID === nID || nde.pID === nID ? { ...nde, ...{
        q
      }} : nde
    )
    const updates = { 
      N: updatedN
    }
    await updateDocument(kID, updates)
    if (!response.error) {
      navigate(`/blockoverview/${jID}/${jA}/${kID}`)
    }
  }

  return (
    <>
    {HMiNode && HmiNode && 
      <div>
        <NavbarProjectBlock jID={jID} jA={jA} kID={kID} kA={document.a} />
        <div className="pt-5">
            <div className="font-mono font-bold">
              <HeadingH3Centered heading={"Edit Node Sequence"} />
            </div>
          </div>
        
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
          <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              
                <input 
                  id="Q"
                  type="text" 
                  required
                  onChange={(e) => setQ(e.target.value)}
                  value={q}
                  className={INPUT_STYLE_1}
                  />                
                <div className="flex gap-3">
                  <button 
                    type='button'
                    onClick={handleCancel}
                    className={BUTTON_CANCEL_STYLE_1}
                    >
                    Back
                  </button>
                  <button 
                    type='submit'
                    className={BUTTON_SUBMIT_STYLE_1}
                    >
                    Update
                  </button>
                </div>
            </form>
          </div>
        </div>
      </div>}
    </>
  )
}