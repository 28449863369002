
// C I J
// d e o t uID

import ListItemXS from "../../components/ListItemXS";
import TextMonoBoldXS from "../../components/TextMonoBoldXS";
import TextMonoXS from "../../components/TextMonoXS";

// o: T/F online status
export default function ZMyProfile({me}) {

  return (
    <>
      <div className='px-1'>
        <div className='flex pt-3'>
          <ListItemXS />
          <div className="flex gap-1">
            <TextMonoBoldXS text={me.d} />
            <TextMonoXS text={me.e} />
          </div>
        </div>

      </div>
    </>
  )
}
