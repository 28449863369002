export const LINK_STYLE_1 = "text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 "

export const LINK_STYLE_2 = "font-body text-sm text-gray-500 hover:text-abhi-link-hover hover:underline hover:underline-offset-4 "

export const LINK_STYLE_3 = "text-sm font-mono font-bold text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 "

export const LINK_STYLE_4 = "font-body text-lg font-bold text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 "

export const LINK_STYLE_5 = "font-mono text-xs text-slate-500 hover:text-abhi-link-hover hover:underline hover:underline-offset-4 "

export const INPUT_STYLE_1 = "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-abhi-red sm:text-sm sm:leading-6"

export const INPUT_STYLE_2 = "block p-3 w-full rounded-md border-0 py-1.5 font-mono text-l leading-loose font-semibold tracking-wide text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500"

export const BUTTON_SUBMIT_STYLE_1 = "flex w-full justify-center rounded-md bg-abhi-red px-3 py-1.5 text-sm font-mono font-semibold leading-6 text-white shadow-sm hover:bg-abhi-link-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-abhi-red"

export const BUTTON_CANCEL_STYLE_1 = "flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-mono font-semibold leading-6 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-900"

export const BUTTON_SUBMIT_STYLE_2 = "flex  justify-end rounded-md bg-abhi-red px-3 py-1.5 text-sm font-mono font-semibold leading-6 text-white shadow-sm hover:bg-abhi-link-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-abhi-red"

export const BUTTON_CANCEL_STYLE_2 = "flex rounded-md bg-slate-700 px-3 py-1.5 text-sm font-mono font-semibold leading-6 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-900"

export const RADIO_1 = "peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all focus:ring-abhi-red"

//export const RADIO_1 = "block rounded-md border-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-abhi-red sm:leading-6"

export const RADIO_2 = "relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"

export const ERROR_STYLE_1 = ""
export const LOADING_STYLE_1 = ""