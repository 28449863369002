import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBxV_TBQ93UJ_Pn7Jj_avSI4ogCagNV7hA",
    authDomain: "abhirama-edition.firebaseapp.com",
    projectId: "abhirama-edition",
    storageBucket: "abhirama-edition.appspot.com",
    messagingSenderId: "813157763806",
    appId: "1:813157763806:web:4ccaaef01211c7a3b5904f"
  };

// init app
initializeApp(firebaseConfig)

// init firestore
const db = getFirestore()

// init firebase auth
const auth = getAuth()

export { db, auth }
