import { Link } from "react-router-dom";
import { LINK_STYLE_3 } from "../../components/Constants";

export default function ZUpgradeLink() {
  
  return (
    <>
      <div className="">
        <Link to="/readings" className={LINK_STYLE_3}>
          Upgrade
        </Link>
      </div>
    </>
  )
}