import { Link, useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocumentA } from "../../hooks/useDocumentA"
import { DotFilledIcon, Pencil2Icon, PlusIcon } from "@radix-ui/react-icons"
import { ERROR_STYLE_1, LINK_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Mono from "../../components/HeadingH3Mono"
import HeadingH3Body from "../../components/HeadingH3Body"
import TextMonoXSRed from "../../components/TextMonoXSRed"
import TextMonoBoldXSTight from "../../components/TextMonoBoldXSTight"
import ZListProjectK from "./ZListProjectK"
import NavbarProject from "../../components/NavbarProject"

export default function ProjectOverview() {
  const { user } = useAuthContext()
  const { jID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const { errorA, documentA } = useDocumentA('USERS', user.uid)
  const [meInWG, setMeInWG] = useState({})

  useEffect(() => {
    if (document && document.WG.length>0){ 
      let result = document.WG.filter(obj => {
        return obj.oUID === user.uid
      })
      setMeInWG(result[0])
    }
  }, [user.uid, document])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  if (errorA) {return <div className={ERROR_STYLE_1}>{errorA}</div>}
  if (!documentA) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  return (
    <>
      <NavbarProject jID={jID} jA={document.a} />
      <div className='grid sm:grid-cols-3 py-3'>
        <div className="px-4 sm:px-12 sm:col-span-2 pb-5">
          <div className="flex justify-start">
            <HeadingH3Mono heading={"Blocks"} />
            { // if quota is not exceeded and if r7 role in this project
              (documentA.qtk < documentA.qtK) && 
              meInWG && meInWG.r7 && 
              <Link to={`/createblock/${jID}`} className={LINK_STYLE_1.concat("mt-3")}>
                <PlusIcon />
              </Link>
            }
          </div>
          <ZListProjectK project={document} meInWG={meInWG} />

        </div>

        <div className="px-4 sm:col-span-1">

          <div className="flex justify-start">
            <HeadingH3Mono heading={"WorkGroup"} />
            {
              meInWG && meInWG.r7 && 
                <Link to={`/wginvitestep1/${jID}`} className={LINK_STYLE_1.concat(" mt-3")}>
                  <PlusIcon />
                </Link>
            }
          </div>
          <div className="flex justify-start">
          {
            document.WG.map(p => (
              <div key={p.oUID} className="flex"> 
                <DotFilledIcon />
                <TextMonoBoldXSTight text={p.do} />
              </div>
            ))
          }
          </div>
          {
            meInWG && meInWG.r3 && 
            <div className="py-1 px-1">
              <Link to={`/projectwgdetails/${jID}`} className={LINK_STYLE_1}>
                <TextMonoXSRed text={"Details..."} />
              </Link>
            </div>
          }
          <hr/>
          <div className="flex justify-start">
            <HeadingH3Body heading={document.a} />
            {// the user is the initiator
              user.uid === document.i &&
              <div className='ml-1 mt-3'>
                <Link to={`/editprojectname/${jID}`} className={LINK_STYLE_1}>
                  <Pencil2Icon />
                </Link>
              </div>
            }
          </div>
          
        </div>
      </div>
    </>
  )
}
