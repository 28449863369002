import { Link } from 'react-router-dom'
import { BoxModelIcon, ChevronRightIcon, DividerVerticalIcon, LayersIcon, PlusIcon, UploadIcon } from '@radix-ui/react-icons'
import { LINK_STYLE_1 } from './Constants'
import xForm from '../hooks/xForm'

export default function NavbarProjectBlock({jID, jA, kID, kA}) {
  const { xFormXaZ } = xForm()
  return (
    <div className="flex font-mono gap-1 text-xs ml-4 py-1">
      <div className='font-bold'>
        <Link to={`/projectoverview/${jID}`} className={LINK_STYLE_1}>
          <div className=''
            dangerouslySetInnerHTML={{ __html: xFormXaZ(jA) }} 
          />
        </Link>
      </div>
      <ChevronRightIcon />
      <Link to={`/blockoverview/${jID}/${jA}/${kID}`} className={LINK_STYLE_1}>
        <div className=''
          dangerouslySetInnerHTML={{ __html: xFormXaZ(kA) }} 
        />
      </Link>
      <ChevronRightIcon />
      <Link to={`/createnodehtmtif/${jID}/${jA}/${kID}`} className={LINK_STYLE_1}>
        <PlusIcon />
      </Link>
      <DividerVerticalIcon />
      <Link to={`/createnodehtmtifyxmultiple/${jID}/${jA}/${kID}`} className={LINK_STYLE_1}>
        <LayersIcon />
      </Link>
      <DividerVerticalIcon />
      <Link to={`/importnodehtmtif1/${jID}/${jA}/${kID}`} className={LINK_STYLE_1}>
        <BoxModelIcon />
      </Link>
      <DividerVerticalIcon />
      <Link to={`/ingressnodes/${jID}/${jA}/${kID}`} className={LINK_STYLE_1}>
        <UploadIcon />
      </Link>

    </div>
  )
}