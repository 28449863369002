export default function HeadingH3Mono({heading}) {
  
  return (
    <>
      <div className="flex mt-1 text-left leading-9">
        <div className="text-abhi-red">&#x2579;</div>
        <div className="text-l tracking-tight font-mono font-bold text-gray-900">
          {heading}  
        </div>
      </div>
    </>
  )
}