
// given a pID i.e. nID of the primary node
// render all nodes that are its secondary

import sortArray from "sort-array"
import ZRenderNodeYx from "./ZRenderNodeYx"
import ZRenderNodeYy from "./ZRenderNodeYy"
import ZRenderNodeYb from "./ZRenderNodeYb"
import ListItemXS from "../../components/ListItemXS"

// and belong to the right panel iF
export default function ZRenderhTmFiFNodes({N, pID, showMenu}) {

  const HmiNodes = N.filter(nde => nde.h && !nde.m && !nde.i && nde.pID === pID)
  return (
    <>
    <div className="flex">
      {!showMenu && 
        <div className="mt-1">
          <ListItemXS />
        </div>
      }
      {HmiNodes && 
        sortArray(HmiNodes, {by:"q", order:"asc"}).map(nd => (
          <div key={nd.nID}>
            <div className="flex leading-8">
              {nd.g === "Yx" && <ZRenderNodeYx node={nd} />}
            </div>
            {nd.g === "Yy" && <ZRenderNodeYy node={nd} />}
            {nd.g === "Yb" && <ZRenderNodeYb node={nd} />}
          </div>
        ))
      }
    </div>
    </>
  )
}