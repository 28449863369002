import { EnterIcon, HomeIcon } from "@radix-ui/react-icons"
import { Link, useNavigate } from "react-router-dom"
import { LINK_STYLE_1 } from "./Constants"

export default function Navbar() {
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate(`/login`)
  }

  return (
    <>
      <div className="grid grid-cols-2 bg-white font-body sticky top-0 z-50">
        <div className="flex justify-start">
          <Link to="/" className={LINK_STYLE_1.concat('ml-4 mr-2 pt-1')} >
            <HomeIcon />
          </Link>
          <Link to="/readings" className={LINK_STYLE_1.concat('mr-2')}>
            readings
          </Link>
        </div>
        <div className="flex justify-end">
          <Link to="/workspace" className={LINK_STYLE_1.concat('mr-2 italic')}>
            workspace
          </Link>
          <button className={LINK_STYLE_1.concat('mr-4')} onClick={handleLogin}>
            <EnterIcon />
          </button>
        </div>
      </div>
    </>
  )
}
