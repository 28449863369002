import { Link } from "react-router-dom"
import { useAuthContext } from "../../hooks/useAuthContext"
import { DividerVerticalIcon } from "@radix-ui/react-icons"
import ListItemXS from "../../components/ListItemXS"
import TextMonoXS from "../../components/TextMonoXS"
import TextMonoBoldXS from "../../components/TextMonoBoldXS"
import GetWGRole from "../../components/GetWGRole"
import { LINK_STYLE_1 } from "../../components/Constants"
import TextMonoXSRed from "../../components/TextMonoXSRed"
import HeadingH3Mono from "../../components/HeadingH3Mono"

export default function ZInviteListPong({inviteList}) {

  const { user } = useAuthContext()

  // inviteList = [{ },...]
  // t timestamp
  // jID id of the project
  // a: display name title of the project
  // iUID ping uID the person sending the invite
  // di: display name of the person
  // ei: email
  // oUID pong uID the person being invited
  // do: name
  // eo: email
  // y: yes accepted
  // n: no declined
  // r9-r1: t/f roles he is supposed to do

  return (
    <>
    {(inviteList.filter(i => !i.y && !i.n).length > 0) &&
      <div className="pt-3">
        <HeadingH3Mono heading={"My Invitations"} />
      </div>
    }
    
    <div className='px-2'>
    {inviteList.map(inv => (
      <div key={inv.jID} >
        {inv.oUID === user.uid && 
          <div className='pb-3'>
          {!inv.y && !inv.n && 
            <>
              <div className="flex">
                <ListItemXS />
                <TextMonoXS text={"By:"} />
                <TextMonoBoldXS text={inv.di} />
              </div>
              <div className='flex'>
                <ListItemXS />
                <TextMonoXS text={"Project:"} />
                <TextMonoBoldXS text={inv.a} />
              </div>
              <div className='flex'>
                <ListItemXS />
                <TextMonoXS text={"My Role:"} />
                <TextMonoBoldXS text={<GetWGRole wgMemb={inv} />} />
              </div>
             
              <div className='flex mx-1 pb-1'>
                <Link to={`/wginvitestep4/${inv.jID}`} className={LINK_STYLE_1}>
                  <TextMonoXSRed text={"accept"} /> 
                </Link>
                <DividerVerticalIcon />
                {!inv.n && 
                  <Link to={`/wginvitestep3/${inv.jID}`} className={LINK_STYLE_1}>
                    <TextMonoXSRed text={"decline"} /> 
                  </Link>
                }
                {inv.n && 
                  <TextMonoBoldXS text={"declined!"} />
                }
              </div>
              <hr/>
            </>
          }
          </div>
        }  
      </div>
    ))
    }
    </div>
    </>
  )
}