
// given a pID i.e. nID of the primary node
// render all nodes that are its secondary

import sortArray from "sort-array"
import ZRenderNodeYx from "./ZRenderNodeYx"
import ZRenderNodeYy from "./ZRenderNodeYy"
import ZRenderNodeYb from "./ZRenderNodeYb"
import { Link } from "react-router-dom"
import { DividerVerticalIcon, DotFilledIcon, Pencil2Icon, TrashIcon } from "@radix-ui/react-icons"
import { LINK_STYLE_3 } from "../../components/Constants"

// and belong to the right panel iF
export default function ZRenderhFmTiFNodes({jID, jA, kID, N, pID, showMenu}) {

  const hMiNodes = N.filter(nde => !nde.h && nde.m && !nde.i && nde.pID === pID)
  return (
    <>
      {hMiNodes && 
        sortArray(hMiNodes, {by:"q", order:"asc"}).map(nd => (
          <div key={nd.nID}>
              <div>
                {showMenu && 
                <div className="flex">
                  {nd.g === "Yx" && 
                    <Link to={`/editnodeyx/${jID}/${jA}/${kID}/${nd.nID}`} 
                      className={LINK_STYLE_3}
                    >
                      <Pencil2Icon />
                    </Link>
                  }
                  {nd.g === "Yy" && 
                    <Link to={`/editnodeyy/${jID}/${jA}/${kID}/${nd.nID}`} 
                      className={LINK_STYLE_3}
                    >
                      <Pencil2Icon />
                    </Link>
                  }
                  <DividerVerticalIcon />
                  <Link to={`/deletenodehfmtfif/${jID}/${jA}/${kID}/${nd.nID}`} 
                    className={LINK_STYLE_3}
                  >
                    <TrashIcon />
                  </Link>
                </div>
                }
                <div className="flex leading-5 text-sm">
                  {!showMenu && 
                  <DotFilledIcon />}
                  {nd.g === "Yx" && <ZRenderNodeYx node={nd} />}
                  {nd.g === "Yy" && <ZRenderNodeYy node={nd} />}
                </div>
              </div>
            
            {nd.g === "Yb" && <ZRenderNodeYb node={nd} />}
          </div>
        ))
      }
    </>
  )
}