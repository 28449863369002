import { useParams } from "react-router-dom"
import { ERROR_STYLE_1, LINK_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import NavbarProjectBlock from "../../components/NavbarProjectBlock"
import { useDocument } from "../../hooks/useDocument"
import { useEffect, useState } from "react"
import { EyeClosedIcon, EyeOpenIcon, MaskOffIcon, MaskOnIcon } from "@radix-ui/react-icons"
import { useAuthContext } from "../../hooks/useAuthContext"
import ZRenderNodeYy from "../nodes/ZRenderNodeYy"
import sortArray from "sort-array"
import ZRenderNodeYx from "../nodes/ZRenderNodeYx"
import ZRenderNodeYb from "../nodes/ZRenderNodeYb"
import ListItemXS from "../../components/ListItemXS"
import ZRenderhTmFiFNodes from "../nodes/ZRenderhTmFiFNodes"
import ZMenuhTmTiF from "./ZMenuhTmTiF"
import ZMenuhTmFiF from "./ZMenuhTmFiF"
import ZRenderhFmTiFNodes from "../nodes/ZRenderhFmTiFNodes"
import ZRenderhFmTiTNodes from "../nodes/ZRenderhFmTiTNodes"
import ZRenderhFmFiFNodes from "../nodes/ZRenderhFmFiFNodes"
import ZRenderhFmFiTNodes from "../nodes/ZRenderhFmFiTNodes"
import TextMonoBoldXS from "../../components/TextMonoBoldXS"
import xBytes from "../../plugs/xBytes"

export default function BlockOverview() {
  const { user } = useAuthContext() 
  const { jID, jA, kID } = useParams() // jA project name
  const { error, document } = useDocument('KNM', kID)
  const { formatBytes } = xBytes()

  const [showMenu, setShowMenu] = useState(true)
  const [showSubsidiaries, setShowSubsidiaries] = useState(true)
  

  const [HMi, setHMi] = useState([]) // h:T m:T i:F nodes
  const [Hmi, setHmi] = useState([]) // h:T m:F i:F nodes
  useEffect(() => {
    if (document){
      setHMi(document.N.filter(nde => nde.h && nde.m && !nde.i))
      setHmi(document.N.filter(nde => nde.h && !nde.m && !nde.i))
    }
  }, [document])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  return (
    <>
      <NavbarProjectBlock jID={jID} jA={jA} kID={kID} kA={document.a} />
      <div className="grid grid-cols-2 bg-white font-body">
        <div className="flex justify-start gap-3 px-4 sm:px-12  py-3">
          {!showMenu && 
            <div onClick={()=>{setShowMenu(true)}} className={LINK_STYLE_1} >
              <MaskOnIcon />
            </div>
          }
          {showMenu && 
            <div onClick={()=>{setShowMenu(false)}} className={LINK_STYLE_1}>
              <MaskOffIcon />
            </div>
          }
          {!showSubsidiaries && 
            <div onClick={()=>{setShowSubsidiaries(true)}} className={LINK_STYLE_1} >
              <EyeClosedIcon />
            </div>
          }
          {showSubsidiaries && 
            <div onClick={()=>{setShowSubsidiaries(false)}} className={LINK_STYLE_1}>
              <EyeOpenIcon />
            </div>
          }
        </div>
        <div className="flex justify-end pr-4 sm:pr-12">
          <TextMonoBoldXS text={formatBytes(JSON.stringify(document, null, 2).length)} />
        </div>
      </div>

      <div>
        {/** Display h:T m:T i:F node */}
        {HMi && 
          sortArray(HMi, {by:"q", order:"asc"}).map(node => (
          <div key={node.nID}>
            <div className='grid sm:grid-cols-3 gap-3'>
              <div className="px-4 sm:px-12 sm:col-span-2">
                {showMenu && 
                  <ZMenuhTmTiF jID={jID} jA={jA} kID={kID} N={document.N} node={node} />
                }
                <div className="flex">
                  {!showMenu && 
                    <div className="mt-1">
                      <ListItemXS />
                    </div>
                  }
                  {node.g === "Yx" && 
                    <div className="leading-8">
                      <ZRenderNodeYx node={node} />
                    </div>
                  }
                  {node.g === "Yy" && <ZRenderNodeYy node={node} />}
                  {node.g === "Yb" && <ZRenderNodeYb node={node} />}
                </div>
                <div className="px-4">
                  {showSubsidiaries && 
                  <ZRenderhFmTiFNodes jID={jID} jA={jA} kID={kID} N={document.N} pID={node.nID} showMenu={showMenu} />
                  }
                </div> 
                <div className="px-4">
                  {showSubsidiaries &&
                  <ZRenderhFmTiTNodes jID={jID} jA={jA} kID={kID} N={document.N} pID={node.nID} showMenu={showMenu} />
                  }
                </div>  
              </div>

              {/** right column */}
              <div className="px-4 sm:col-span-1">
              {Hmi && Hmi.filter(nd => nd.pID === node.nID).length ===1 && 
                <div>
                  {showMenu  && 
                    <ZMenuhTmFiF jID={jID} jA={jA} kID={kID} N={document.N} pID={node.nID} />
                  }
                  <div>
                    {/** Display h:T m:F i:F node */}
                    <ZRenderhTmFiFNodes N={document.N} pID={node.nID} showMenu={showMenu}/>
                  </div>
                  <div>
                    {showSubsidiaries &&
                    <div>
                    <ZRenderhFmFiFNodes jID={jID} jA={jA} kID={kID} N={document.N} 
                    pID={Hmi.filter(nd => nd.pID === node.nID)[0].nID} showMenu={showMenu} />
                    <ZRenderhFmFiTNodes jID={jID} jA={jA} kID={kID} N={document.N} 
                    pID={Hmi.filter(nd => nd.pID === node.nID)[0].nID} showMenu={showMenu} />
                    </div>
                    }
                  </div> 
                </div>
              }
              </div>

              
            </div>
          </div>
          ))
        }
      </div>
    </>
  )
}
