import { Link, useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocumentA } from "../../hooks/useDocumentA"
import sortArray from "sort-array"
import { EyeOpenIcon, EyeNoneIcon, DownloadIcon, Pencil2Icon } from "@radix-ui/react-icons"
import { ERROR_STYLE_1, LINK_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"

import TextBodyBoldXL from "../../components/TextBodyBoldXL"
import GetWGRole from "../../components/GetWGRole"
import TextMonoBoldSM from "../../components/TextMonoBoldSM"
import TextMonoSM from "../../components/TextMonoSM"
import NavbarProjectBlock from "../../components/NavbarProjectBlock"

export default function ManageK() {
  const { user } = useAuthContext()
  const { jID, kID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const { errorA, documentA } = useDocumentA('KNM', kID)
  const [meInWG, setMeInWG] = useState({})
  const [kInProjectsKNMList, setKInProjectsKNMList] = useState({})

  useEffect(() => {
    if (document && document.WG){ 
      let result = document.WG.filter(obj => {
        return obj.oUID === user.uid
      })
      setMeInWG(result[0])
    }
    if (document && document.KNM){ 
      let kresult = document.KNM.filter(k => {
        return k.kID === kID
      })
      setKInProjectsKNMList(kresult[0])
    }
  }, [document, kID, user.uid])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  if (errorA) {return <div className={ERROR_STYLE_1}>{errorA}</div>}
  if (!documentA) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  return (
    <>
      {/** Navbar for wgMember */}
      <NavbarProjectBlock jID={jID} jA={document.a} kID={kID} kA={documentA.a} /> 
      {/** Navbar ends here */}

      <div className='grid sm:grid-cols-1 py-3'>
        <div className="px-4 sm:px-12 sm:col-span-1 pb-5">

          <div className="flex justify-start gap-3">
            {
              (user.uid === documentA.c || // if this user is also the creator of the this block 
              meInWG.r9) && // or r9
                <div className="flex pt-1">              
                  <Link 
                    to={`/getblockjson/${jID}/${document.a}/${kID}`} 
                    className={LINK_STYLE_1}
                  > 
                    <DownloadIcon />
                  </Link>
                </div>
            }
            {
              (user.uid === documentA.c || // if this user is also the creator of the this block 
                meInWG.r7) && // or r7 
                <div className="flex pt-1">              
                  <Link 
                    to={`/editblocktitle/${documentA.jID}/${documentA.kID}`} 
                    className={LINK_STYLE_1}
                  > 
                    <Pencil2Icon />
                  </Link>
                </div>
            }
            {
              documentA.a && 
              <TextBodyBoldXL text={documentA.a} />
            }
          </div>
          <div className='pt-3'>
            {
              kInProjectsKNMList.wf && 
              sortArray(kInProjectsKNMList.wf, {by:"q", order:'asc'}).map(w => (
                <div key={w.uID}>
                  <div className="flex gap-3 pt-3 pb-1">
                
                  {
                    (
                      w.uID === document.i || (
                        document.WG.filter(m => {
                          return m.oUID === w.uID
                        })[0].r7
                      )
                    ) && // Can't be changed
                    ( w.v ) && // Can't be changed & visible!
                    <div className="pt-1">
                      <EyeOpenIcon/>
                    </div>
                  }

                  {
                    (
                      w.uID === document.i || (
                        document.WG.filter(m => {
                          return m.oUID === w.uID
                        })[0].r7
                      )
                    ) && // Can't be changed
                    ( !w.v ) && // Can't be changed & INvisible!!
                    <div className="pt-1">
                      <EyeNoneIcon/>
                    </div>
                  }

                  {
                    (
                      w.uID !== document.i && (
                      !document.WG.filter(m => {
                          return m.oUID === w.uID
                        })[0].r7
                      )
                    ) && // Can be changed
                    ( w.v ) && // Can be changed & visible!!
                    <div className="pt-1">
                      <Link to={`/managekmakeinvisible/${jID}/${kID}/${w.uID}`} 
                        className={LINK_STYLE_1}>
                        <EyeOpenIcon/>
                      </Link>
                    </div>
                  }

                  {
                    (
                      w.uID !== document.i && (
                      !document.WG.filter(m => {
                          return m.oUID === w.uID
                        })[0].r7
                      )
                    ) && // Can be changed
                    ( !w.v ) && // Can be changed & INvisible!!
                    <div className="pt-1">
                      <Link to={`/managekmakevisible/${jID}/${kID}/${w.uID}`} 
                        className={LINK_STYLE_1}>
                        <EyeNoneIcon/>
                      </Link>
                    </div>
                  }
                  <div className="flex gap-1 pt-1" >
                    <div className="text-left text-sm font-mono text-gray-700">
                      <GetWGRole wgMemb={document.WG.filter(m => {
                        return m.oUID === w.uID
                      })[0]} />:
                    </div>
                    <TextMonoBoldSM text={w.d} />
                    <TextMonoSM text={w.e} />
                    
                   
                  </div>
                  
                  </div>
                  <hr/>

                </div>
              ))
            }

          </div>
         
        </div>
      </div>
      
    </>
  )
}
