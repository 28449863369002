
// C I J
// d e o t uID

import ListItemXS from "../../components/ListItemXS";
import TextMonoBoldXS from "../../components/TextMonoBoldXS";
import TextMonoXS from "../../components/TextMonoXS";
import ZUpgradeLink from "../upgrade/ZUpgradeLink";

// o: T/F online status
export default function ZMyQuota({me}) {

  return (
    <>
      <div className='px-1'>
        <div className='flex pt-3'>
          <ListItemXS />
          <div className="flex gap-1">
            <TextMonoBoldXS text={me.d} />
            <TextMonoXS text={me.e} />
          </div>
        </div>

        <div className='flex pt-3'>
          <ListItemXS />
          <div className="flex gap-1">
            <TextMonoBoldXS text={"Projects:"} />
            <div className="flex">
              <TextMonoBoldXS text={me.qtj} />
              <TextMonoXS text={"/"} />
              <TextMonoXS text={me.qtJ} />
            </div>
          </div>
        </div>

        <div className='flex pt-3'>
          <ListItemXS />
          <div className="flex gap-1">
            <TextMonoBoldXS text={"Blocks:"} />
            <div className="flex">
              <TextMonoBoldXS text={me.qtk} />
              <TextMonoXS text={"/"} />
              <TextMonoXS text={me.qtK} />
            </div>
          </div>
        </div>

        <div className='flex pt-3'>
          <ListItemXS />
          <div className="flex gap-1">
            <TextMonoBoldXS text={"Colleagues:"} />
            <div className="flex">
              <TextMonoBoldXS text={me.C.filter(c=>{return c.y}).length} />
              <TextMonoXS text={"/"} />
              <TextMonoXS text={me.qtC} />
            </div>
          </div>
        </div>

        <div className='flex pt-3'>
          <ListItemXS />
          <div className="flex gap-1">
            <TextMonoBoldXS text={"Pings:"} />
            <div className="flex">
              <TextMonoBoldXS text={me.C.filter(c=>{return c.i && c.y}).length} />
              <TextMonoXS text={"/"} />
              <TextMonoXS text={me.qtCi} />
            </div>
          </div>
        </div>

        <div className='flex pt-3'>
          <ListItemXS />
          <div className="flex gap-1">
            <TextMonoBoldXS text={"Workgroup:"} />
            <div className="flex">
              <TextMonoBoldXS text={"max."} />
              <TextMonoXS text={me.qtWG} />
            </div>
          </div>
        </div>


        <div className='flex pt-3'>
          <ZUpgradeLink />
        </div>


      </div>
    </>
  )
}
