import { toast } from "react-toastify"
import { useDocument } from "../../hooks/useDocument"
import { Link, useNavigate, useParams } from "react-router-dom"
import HeadingH3Centered from "../../components/HeadingH3Centered"
import { BUTTON_SUBMIT_STYLE_1, LINK_STYLE_1, LINK_STYLE_3, LOADING_STYLE_1 } from "../../components/Constants"

// here the email id is received
// check in EMAILuID if there is a corresponding entry
// if no, then go back to profile page with a caption
// this email is not registered
// if registered, but already invited for collaboration, then 
// go back to profile with the caption, 
// communication already in process
// otherwise goto next step 3
export default function CollaborationReq2() {
  const { email } = useParams()
  const { error, document } = useDocument('EMAILuID', email)
  const notify = () => toast("Unregistered user!")
  const navigate = useNavigate()

  if (error) {
    return (
      <>
        <HeadingH3Centered heading={email} />
        <HeadingH3Centered heading={"does not exist!"} />
        <div className="justify-center font-body pt-1">
          <Link to="/CollaborationReq1" className={LINK_STYLE_1}>
              Request again with another email.
          </Link>
        </div>
      </>
    )
  }
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (document.e === email) {
      navigate(`/collaborationreq3/${document.uID}`)
    } else {
      notify()
      navigate('/profile')
    }
  }

  return (
    <>
    <div className="pt-5">
      <div className="font-mono font-bold">
        <HeadingH3Centered heading={"You are about to send a request to"} />
      </div>
      <div className="flex justify-center gap-3 font-mono font-bold">
        <HeadingH3Centered heading={document.d} />
        <HeadingH3Centered heading={document.e} />
      </div>
    </div>
    
    <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <button type="submit" className={BUTTON_SUBMIT_STYLE_1} >
              Send request
          </button>
        </div>
      </form>
    </div>
    <div className="justify-center text-center pt-3">
      <Link to={`/profile/`} className={LINK_STYLE_3}>
        Cancel
      </Link>
    </div>

    <div className="flex justify-center font-mono font-bold pt-3">
      The requested person may accept or reject your request.
    </div>

    </>
  )
}
