import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { PlusIcon } from "@radix-ui/react-icons"
import ZWGList from "./ZWGList"
import ZInviteList from "./ZInviteList"
import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocumentA } from "../../hooks/useDocumentA"
import HeadingH3Mono from "../../components/HeadingH3Mono"
import { LINK_STYLE_1 } from "../../components/Constants"
import NavbarProject from "../../components/NavbarProject"

export default function ProjectWGDetails() {
  const { user } = useAuthContext()
  const { jID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const { errorA, documentA } = useDocumentA('USERS', user.uid)
  const [wgMember, setWgMember] = useState({})

  useEffect(() => {
    if (document && document.WG){ 
      let result = document.WG.filter(obj => {
        return obj.oUID === user.uid
      })
      setWgMember(result[0])
    }
  }, [user.uid, document])

  if (error) {return <div className="error">{error}</div>}
  if (!document) {return <div className="loading">Loading ...</div>}
  if (errorA) {return <div className="error">{errorA}</div>}
  if (!documentA) {return <div className="loading">Loading ...</div>}
 
  return (
    <>
      <NavbarProject jID={jID} jA={document.a} />

      <div className='grid sm:grid-cols-3 py-3'>

        <div className="px-4 sm:px-12 sm:col-span-2 pb-5">

          <div className="flex justify-start">
            <HeadingH3Mono heading={"WorkGroup"} />
            {
              wgMember && wgMember.r7 && 
              <div className="pt-3">
                <Link to={`/wginvitestep1/${jID}`} className={LINK_STYLE_1}>
                  <PlusIcon />
                </Link>
              </div>
            }
          </div>

          <div className=''>
            <ZWGList project={document} />  
          </div>
        </div>

        <div className="px-4 sm:col-span-1">

          <div className="justify-start">
            <ZInviteList inviteList={document.I} />
          </div>
          
          {/** only when there are open invitations */}
      
        </div>
      </div>
    </>
  )
}
