import { useState } from 'react';
import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, INPUT_STYLE_2, LOADING_STYLE_1 } from '../../components/Constants';
import { useDocument } from '../../hooks/useDocument'
import { useNavigate, useParams } from 'react-router-dom';
import { useFirestore } from '../../hooks/useFirestore';
import HeadingH3Centered from '../../components/HeadingH3Centered';

// create a Node within the block kID (in project jID) 
// a node is created ONLY within a project/block 
// by those who have r5/admin role (editors r3 work only on given nodes)
// all nodes with i:F are visible if the block is visible
// N:[] is the array within block kID in KNM
// the node object is pushed to this array

// nID unique node ID 
// t: is last update
// q: for sequencing
// h: true as it is pradhAna node
// m: true if left panel
// i: false if not individual / personal
// g: Yx / Yy / Yb types or Genus of node
// x: MAIN text HK
// y: Main text in eng mixed
// b: main bib title
// pID and uID are not needed here

export default function IngressNodes() {
  const { jID, jA, kID } = useParams()
  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 
  const navigate = useNavigate()

  const [NArr, setNArr] = useState('')

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  
  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/blockoverview/${jID}/${jA}/${kID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // base fields
    const jsonifiedNArr = JSON.parse(NArr)
    
    const updates = { 
      N: document.N.concat(jsonifiedNArr) 
      // N  array
    }
    // update the block
    await updateDocument(kID, updates)

    if (!response.error) {
      navigate(`/blockoverview/${jID}/${jA}/${kID}`)
    }
  }

  return (
    <>
      <div className="pt-5">
        <div className="font-mono font-bold">
          <HeadingH3Centered heading={"Create a node h:true m:true i:false Yx:true"} />
        </div>
      </div>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>

            <div className="mt-2">
              <textarea 
                id="NArr" 
                name="NArr" 
                rows="15" 
                placeholder='[{},{}]'
                className={INPUT_STYLE_2}
                onChange={(e) => setNArr(e.target.value)}
                value={NArr}
              >
              </textarea>
            </div>
        
            <div className="flex gap-3">
              <button 
                type='button'
                onClick={handleCancel}
                className={BUTTON_CANCEL_STYLE_1}
              >
                Cancel
              </button>
              <button 
                type='submit'
                className={BUTTON_SUBMIT_STYLE_1}
              >
                Ingress
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
