import xForm from "../../hooks/xForm"

export default function ZRenderNodeYy({node}) {
  const { xFormXaZ } = xForm()
  return (
    <>
      <div className='font-body text-sm leading-6'
        dangerouslySetInnerHTML={{ __html: xFormXaZ(node.y) }} 
      />
    </>
  )
}