import xForm from "../../hooks/xForm"

export default function ZRenderNodeYx({node}) {
  const { xFormXa } = xForm()
  return (
    <>
      <div className='font-body'
        dangerouslySetInnerHTML={{ __html: xFormXa(node.x) }} 
      />
    </>
  )
}