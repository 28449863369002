import xForm from "../hooks/xForm"

export default function TextBodyBoldXL({text}) {
  const { xFormXaZ } = xForm()
  
  return (
    <>
      <div className="text-left text-xl font-body font-bold text-slate-900">
        <div className='font-body text-lg leading-6'
          dangerouslySetInnerHTML={{ __html: xFormXaZ(text) }} 
        />
      </div>
    </>
  )
}