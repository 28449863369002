import HeadingH1Centered from "../../components/HeadingH1Centered";

export default function Readings() {

  return (
    <>
      <HeadingH1Centered heading={"Readings"} />
      <p className="text-center font-mono text-xs text-gray-500">
        Coming soon!
      </p>
    </>
  )
}
