import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import { BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, INPUT_STYLE_1, LINK_STYLE_3, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Centered from "../../components/HeadingH3Centered"

// first an email id is inputted
// this will be passed on to check if that person is a registered user
// this is done in step 2
export default function CollaborationReq1() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const notify = () => toast("You are already there!")
  const notify1 = () => toast("Already there!")

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()
    // my contacts document.C
    if (user.email === email) {
      notify()
      navigate('/profile')
    } else if (document.C.filter(e => e.e === email).length === 0){
      navigate(`/collaborationreq2/${email}`)
    } else {
      notify1()
      navigate('/profile')
    }
  }

  return (
    <>
    <div className="pt-5">
      <div className="font-mono font-bold">
        <HeadingH3Centered heading={"Please input the Email-User-ID of the person"} />
      </div>
    </div>

    <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="name@example.com"
              required
              onChange={(e) => setEmail(e.target.value)} 
              value={email}
              className={INPUT_STYLE_1}
            />
          </div>
        </div>

        <div>
          <button type="submit" className={BUTTON_SUBMIT_STYLE_1} >
            Send request
          </button>
        </div>
        <div>
          {error && <div>{error}</div>}
        </div>
      </form>

      <div className="justify-center text-center pt-3">
        <Link to={`/profile/`} className={LINK_STYLE_3}>
          Cancel
        </Link>
      </div>
    </div>
    </>
  )
} 
