import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"
import { useDocumentA } from "../../hooks/useDocumentA"
import { useFirestoreA } from "../../hooks/useFirestoreA"
import { useState } from "react"

// within the project jID
// user with user.uid is inviting 
// oUID to join the WG
// when send invitation is pressed

// an invitation packet is stored at
// Projects:jID.I so that we know which invitations are active for this project
// Any WG member of the project can see this invitation

// USERS.oUID.I so that the invited person knows that he is being invited

// The invitation packet consists of:
// jID id of the project
// a: display name title of the project
// iUID ping uID the person sending the invite
// di: display name of the person
// ei: email
// oUID pong uID the person being invited
// do: name
// eo: email
// r9-r1: t/f roles he is supposed to do

// He can either accept this or reject
// for this next step.

// If he accepts then
// Projects jID.WG will include him with this role.
// If he reclines, then this is noted in Projects.jID.I and oUID.I 

export default function WGInviteStep2() {
  const { user } = useAuthContext()
  // oUID uID of the person invited
  const { jID, oUID } = useParams()

  const { error, document } = useDocument('Projects', jID)
  const { errorA, documentA } = useDocumentA('USERS', oUID)

  const { updateDocument, response } = useFirestore('Projects') 
  const { updateDocumentA, responseA } = useFirestoreA('USERS') 

  const [checkedRoles, setCheckedRoles] = useState([])
  const navigate = useNavigate()

  if (error) {return <div className="error">{error}</div>}
  if (!document) {return <div className="loading">Loading ...</div>}
  if (errorA) {return <div className="error">{errorA}</div>}
  if (!documentA) {return <div className="loading">Loading ...</div>}

  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/wginvitestep1/${jID}`)
  }

  const handleCheck = (e) => {
    if (e.target.checked) {
      setCheckedRoles([...checkedRoles, e.target.value]);
    } else {
      setCheckedRoles(checkedRoles.filter((item) => item !== e.target.value));
    }
  }

  // yet to check that I do not send a request to myself!
  const handleSubmit = async (e) => {
    e.preventDefault()

    let r9 = false
    let r7 = false
    let r5 = false
    let r3 = false
    let r1 = false

    if (checkedRoles.includes("r9")) {
      r9 = true
      r7 = true
      r5 = true
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r7")) {
      r7 = true
      r5 = true
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r5")) {
      r5 = true
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r3")) {
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r1")) {
      r1 = true
    } 

    // t timestamp
    // jID id of the project
    // a: display name title of the project
    // iUID ping uID the person sending the invite
    // di: display name of the person
    // ei: email
    // oUID pong uID the person being invited
    // do: name
    // eo: email
    // g: project.g project type
    // r9-r1: t/f roles he is supposed to do

    let invPayload = {
      t:  Date.now(),
      jID,
      a:document.a, // 
      iUID:user.uid,
      di:user.displayName,
      ei:user.email,
      oUID,
      do:documentA.d,
      eo:documentA.e,
      //g:document.g, // project type
      y:false, // yes accepted
      n:false, // no declined
      r9,
      r7,
      r5,
      r3,
      r1
    }

    let arrInviteProj = document.I // invite in project
    // if the jID is not already there     
    if (arrInviteProj.filter(e => e.oUID === oUID).length === 0) {
      arrInviteProj.push({
        ...invPayload
      })
    }

    const updatesInviteProj = { 
      I: arrInviteProj
    }

    // save updatesInviteProj to Proj
    await updateDocument(jID, updatesInviteProj)
    

    let arrInvitePong = documentA.I // invite 
    // if the jID is not already there 

    if (arrInvitePong.filter(e => e.jID === jID).length === 0) {
      arrInvitePong.push({
        t:  Date.now(),
        jID,
        a:document.a, // 
        iUID:user.uid,
        di:user.displayName,
        ei:user.email,
        oUID,
        do:documentA.d,
        eo:documentA.e,
        //g:document.g, // project type
        y:false, // yes accepted
        n:false, // no declined
        r9,
        r7,
        r5,
        r3,
        r1
      })
    }

    const updatesInvitePong = { 
      I: arrInvitePong // I invitation array
    }

    // save updatesInvitePong to Pong account
    await updateDocumentA(oUID, updatesInvitePong)
    if (!response.error && !responseA.error) {
      navigate(`/projectoverview/${jID}`)
    }
  }

  return (
    <>

      <div className="flex min-h-full flex-1 flex-col justify-start px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/*<AbhiNameWithLogo />*/}
          <div className="mt-10 text-start text-l font-mono leading-9 tracking-tight text-gray-900">
            Project:&nbsp;{document.a}
          </div>
          <div className="mt-1 text-start text-xs font-mono leading-3 tracking-tight text-gray-900">
            Invite:&nbsp;{documentA.d}&nbsp;[{documentA.e}]
          </div>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex pt-3">
            <input 
              value = "r9" 
              type = "checkbox" 
              onChange = {handleCheck} 
              className="block rounded-md border-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-abhi-red sm:leading-6"
            /> 
            <div className="px-3 text-sm font-mono text-slate-500">Owner r9</div>
          </div>

          <div className="flex pt-3">
            <input 
              value = "r7" 
              type = "checkbox" 
              onChange = {handleCheck} 
              className="block rounded-md border-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-abhi-red sm:leading-6"
            /> 
            <div className="px-3 text-sm font-mono text-slate-500">Manager r7</div>
          </div>

          <div className="flex pt-3">
            <input 
              value = "r5" 
              type = "checkbox" 
              onChange = {handleCheck} 
              className="block rounded-md border-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-abhi-red sm:leading-6"
            /> 
            <div className="px-3 text-sm font-mono text-slate-500">Admin r5</div>
          </div>

          <div className="flex pt-3">
            <input 
              value = "r3" 
              type = "checkbox" 
              onChange = {handleCheck} 
              className="block rounded-md border-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-abhi-red sm:leading-6"
            /> 
            <div className="px-3 text-sm font-mono text-slate-500">Editor r3</div>
          </div>

          <div className="flex pt-3">
            <input 
              value = "r1" 
              type = "checkbox" 
              onChange = {handleCheck} 
              className="block rounded-md border-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-abhi-red sm:leading-6"
            /> 
            <div className="px-3 text-sm font-mono text-slate-500">Reader r1</div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex gap-3 pt-5">
              <button
                onClick={handleCancel}
                  className="flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-mono font-semibold leading-6 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-abhi-red px-3 py-1.5 text-sm font-mono font-semibold leading-6 text-white shadow-sm hover:bg-abhi-link-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-abhi-red"
              >
                Send invitation
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
