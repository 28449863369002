import { useState, useEffect } from "react"
import { toast } from 'react-toastify'
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import 'react-toastify/dist/ReactToastify.css'

import xForm from "../../hooks/xForm"
import { BUTTON_CANCEL_STYLE_2, BUTTON_SUBMIT_STYLE_2, ERROR_STYLE_1, INPUT_STYLE_1, INPUT_STYLE_2, LOADING_STYLE_1 } from "../../components/Constants"
import { useNavigate, useParams } from "react-router-dom"
import NavbarProjectBlock from "../../components/NavbarProjectBlock"
import useCopyToClipboard from "../../hooks/useCopyToClipboard"
import { CheckIcon, CopyIcon } from "@radix-ui/react-icons"
import TextBodyBoldRed from "../../components/TextBodyBoldRed"

// Edit the node of type Yy
// y main english text with sanskrit words
export default function EditNodeYb() {
  const { jID, jA, kID, nID } = useParams()

  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 

  const [b, setB] = useState('') // cite 
  const [a, setA] = useState('') // author/editor
  const [tl, setTl] = useState('') // title for display 
  const [j, setJ] = useState('') // jahr 
  const [eID, setEID] = useState('') // edition ID manually set pdf filename
  const [lk, setLk] = useState('') // link jstor heidi
  const [lv, setLv] = useState('') // link archive id
  const [isCopied, handleCopy] = useCopyToClipboard(3000)
  
  const [knode, setKNode] = useState({})
  const { xFormXaZ } = xForm()
  const navigate = useNavigate()
  const notify = () => toast("Updated!")

  useEffect(() => {
    if (document){
      const selectedN = document.N.filter(nde => nde.nID === nID)
      if (selectedN.length > 0) {
        setKNode(selectedN[0])
        setB(selectedN[0].b)
        setA(selectedN[0].a)
        setJ(selectedN[0].j)
        setTl(selectedN[0].tl)
        setLk(selectedN[0].lk)
        setLv(selectedN[0].lv)
        setEID(selectedN[0].eID)
      }
    }
  }, [document, nID])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/blockoverview/${jID}/${jA}/${kID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedN = document.N.map((nde) => 
    nde.nID === nID ? { ...nde, ...{
        b, a, j, tl, lk, lv, eID,
        l: Date.now() // update last edited
      }} : nde
    )
    const updates = { 
      N: updatedN
    }
    await updateDocument(kID, updates)
    if (!response.error) {
      notify()
    }
  }

  return (
    <>
    {knode.g === "Yb" && 
      <div>
        <NavbarProjectBlock jID={jID} jA={jA} kID={kID} kA={document.a} />
        
        <form onSubmit={handleSubmit}>
          <div className="grid gap-5 sm:grid-cols-3 py-3">
            <div className='px-4 sm:px-12 sm:col-span-1'>
              <input 
                id="j"
                type="text" 
                onChange={(e) => setJ(e.target.value)}
                value={j}
                className={INPUT_STYLE_1}
              /> 
            </div>
            <div className="px-4 sm:col-span-1">
            </div>
            <div className="px-4 sm:col-span-1">
            </div>
          </div>

          <div className="grid gap-5 sm:grid-cols-3 py-3">
            <div className='px-4 sm:px-12 sm:col-span-1'>
              <input 
                id="lv"
                type="text" 
                onChange={(e) => setLv(e.target.value)}
                value={lv}
                className={INPUT_STYLE_1}
              /> 
              
            </div>
            <div className="px-4 sm:col-span-1">
              <input 
                id="lk"
                type="text" 
                onChange={(e) => setLk(e.target.value)}
                value={lk}
                className={INPUT_STYLE_1}
              /> 
            </div>
            <div className="px-4 sm:col-span-1">
              <input 
                id="eID"
                type="text" 
                onChange={(e) => setEID(e.target.value)}
                value={eID}
                className={INPUT_STYLE_1}
              /> 
            </div>
          </div>

          <div className="grid gap-5 sm:grid-cols-3 py-3">
            <div className='px-4 sm:px-12 sm:col-span-1'>
              <div className='font-mono tracking-tight text-sm leading-loose text-slate-900'
                dangerouslySetInnerHTML={{ __html: xFormXaZ(a) }} />
              <input 
                id="a"
                type="text" 
                onChange={(e) => setA(e.target.value)}
                value={a}
                className={INPUT_STYLE_1}
              /> 
            </div>
            <div className="px-4 sm:col-span-2">
              <div className='font-body tracking-tight leading-loose text-slate-900'
                dangerouslySetInnerHTML={{ __html: xFormXaZ(tl) }} />
              <input 
                id="tl"
                type="text" 
                onChange={(e) => setTl(e.target.value)}
                value={tl}
                className={INPUT_STYLE_1}
              /> 
              <div className="pt-3" onClick={() => handleCopy(
                tl.split(" ").map(
                  w => w.charAt(0)+ w.slice(1).toLowerCase()
                ).join(" ")
              )}>
                  { isCopied ? 
                    <div className='flex text-abhi-red'>
                      <CheckIcon />
                      <TextBodyBoldRed text={"copied"} />
                    </div> : 
                    <div className='flex gap-1 text-abhi-red'>
                      <CopyIcon />
                      <TextBodyBoldRed text={"copy Lower Case version"} />
                    </div>
                  }
                </div>
            </div>
          </div>

          <div className="grid gap-5 sm:grid-cols-1 pt-3">
            <div className='px-4 sm:px-12 sm:col-span-1'>
              <textarea 
                id="b" 
                  name="b" 
                  rows="2" 
                  className={INPUT_STYLE_2}
                  onChange={(e) => setB(e.target.value)}
                  value={b}
                >
                </textarea>
            </div>
        
      
          </div>

          <div className="grid gap-5 sm:grid-cols-3 py-3">
            <div className='px-4 sm:px-12 sm:col-span-1'>
            </div>
            <div className="px-4 sm:col-span-1">
              <div className="flex justify-center gap-3 pt-3 px-3">
                  <button 
                    type='button'
                    onClick={handleCancel}
                    className={BUTTON_CANCEL_STYLE_2}
                    >
                    Back
                  </button>
                  <button 
                    type='submit'
                    className={BUTTON_SUBMIT_STYLE_2}
                    >
                    Update
                  </button>
              </div>
            </div>
            <div className="px-4 sm:col-span-1">
            </div>
          </div>

          <div className="grid gap-5 sm:grid-cols-3 py-3">
            <div className='px-4 sm:px-12 sm:col-span-1'>
            </div>
            <div className="px-4 sm:col-span-1">
            </div>
            <div className="px-4 sm:col-span-1">
            </div>
          </div>

        </form>
      </div>}
    </>
  )
}