import { useState, useEffect } from "react"
import { toast } from 'react-toastify'
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import 'react-toastify/dist/ReactToastify.css'

import xForm from "../../hooks/xForm"
import { BUTTON_CANCEL_STYLE_2, BUTTON_SUBMIT_STYLE_2, ERROR_STYLE_1, INPUT_STYLE_2, LOADING_STYLE_1 } from "../../components/Constants"
import { useNavigate, useParams } from "react-router-dom"
import NavbarProjectBlock from "../../components/NavbarProjectBlock"

// Edit the node of type Yx
// x main sanskrit text hk
export default function EditNodeYx() {
  const { jID, jA, kID, nID } = useParams()

  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 

  const [x, setX] = useState('')
  const [knode, setKNode] = useState({})
  const { xFormXa } = xForm()
  const navigate = useNavigate()
  const notify = () => toast("Updated!")

  useEffect(() => {
    if (document){
      const selectedN = document.N.filter(nde => nde.nID === nID)
      if (selectedN.length > 0) {
        setKNode(selectedN[0])
        setX(selectedN[0].x)
      }
    }
  }, [document, nID])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/blockoverview/${jID}/${jA}/${kID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedN = document.N.map((nde) => 
    nde.nID === nID ? { ...nde, ...{
        x,
        l: Date.now() // update last edited
      }} : nde
    )
    const updates = { 
      N: updatedN
    }
    await updateDocument(kID, updates)
    if (!response.error) {
      notify()
    }
  }

  return (
    <>
    {knode.g === "Yx" && 
      <div>
        <NavbarProjectBlock jID={jID} jA={jA} kID={kID} kA={document.a} />
        
        <div className="grid gap-5 sm:grid-cols-2 py-3">
          <div className='px-4 sm:px-12 sm:col-span-1'>
            <div className='font-body tracking-wide text-xl leading-loose text-slate-900'
              dangerouslySetInnerHTML={{ __html: xFormXa(x) }} />
          </div>
        
          <div className="px-4 sm:col-span-1">
            <form onSubmit={handleSubmit}>
              <div className="mt-2">
                <textarea 
                  id="editX" 
                  name="editX" 
                  rows="15" 
                  className={INPUT_STYLE_2}
                  onChange={(e) => setX(e.target.value)}
                  value={x}
                >
                </textarea>
              </div>

              <div className="flex justify-between gap-3 pt-3 px-3">
                <label htmlFor="editX" className="block text-xs font-mono text-slate-500">Edit this passage (अ-a)</label>
                <div className="flex justify-end gap-3 px-3">
                  <button 
                    type='button'
                    onClick={handleCancel}
                    className={BUTTON_CANCEL_STYLE_2}
                    >
                    Back
                  </button>
                  <button 
                    type='submit'
                    className={BUTTON_SUBMIT_STYLE_2}
                    >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>}
    </>
  )
}