import xForm from "../hooks/xForm"

export default function HeadingH3Body({heading}) {
  const { xFormXaZ } = xForm()
  return (
    <>
      <div className="flex mt-3 text-left leading-4">
        <div className="text-abhi-red">&#x2579;</div>
        <div className="text-l font-body font-bold text-gray-900">
          <div className=''
            dangerouslySetInnerHTML={{ __html: xFormXaZ(heading) }} 
          />
        </div>
      </div>
    </>
  )
}