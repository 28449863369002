import { useState } from 'react'
import { useSignup } from '../../hooks/useSignup'
import { Link } from 'react-router-dom'
import HeadingH1Centered from '../../components/HeadingH1Centered'
import { BUTTON_SUBMIT_STYLE_1, INPUT_STYLE_1, LINK_STYLE_1 } from '../../components/Constants'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const { signup, error } = useSignup()
  
  const handleSubmit = (e) => {
    e.preventDefault()
    signup(email, password, displayName)
  }

  return (
    <>
      <HeadingH1Centered heading={"Sign up"} />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-mono font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setEmail(e.target.value)} 
                  value={email}
                  className={INPUT_STYLE_1}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-mono font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-xs">
                  <Link to="#" className="font-mono text-abhi-link hover:text-abhi-link-hover">
                    at least 6 chars
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)} 
                  value={password}
                  className={INPUT_STYLE_1}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-mono font-medium leading-6 text-gray-900">
                  Display name
                </label>
                <div className="text-xs">
                  <Link to="#" className="font-mono text-abhi-link hover:text-abhi-link-hover">
                    please type your actual name
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="displayName"
                  name="displayName"
                  type="text"
                  autoComplete="your actual name"
                  required
                  onChange={(e) => setDisplayName(e.target.value)} 
                  value={displayName}
                  className={INPUT_STYLE_1}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className={BUTTON_SUBMIT_STYLE_1}
              >
                Sign up
              </button>
            </div>
            <div>
              {error && <div>{error}</div>}
            </div>
          </form>

          <p className="mt-10 text-center font-mono text-xs text-gray-500">
            Membership is free.{' '}
            <Link to="#" className={LINK_STYLE_1}>
              Terms and Conditions
            </Link>
          </p>

          <p className="text-center font-mono text-xs text-gray-500">
            Already a member?{' '}
            <Link to="/login" className={LINK_STYLE_1}>
              Sign in
            </Link>
          </p>
          
        </div>
      </div>
    </>
  )
}