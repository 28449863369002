import { Link, useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/config"
import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, INPUT_STYLE_1, LINK_STYLE_3, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Centered from "../../components/HeadingH3Centered"
import xForm from "../../hooks/xForm"

// You must be the initiator of this project to edit its name
// First update the Projects Coll at the right jID
// Then loop over the Projects[jID].WG of this project
// Get the oUID of all the participants
// Open the USERS with this oUID, pass on also jID
// Go to their J: field and filter this jID obj
// There, update the a field
export default function EditProjectName() {

  const { user } = useAuthContext()
  const { jID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const { updateDocument, response } = useFirestore('Projects') 
  const navigate = useNavigate()
  const notify = () => toast("Updated!")
  const { xFormXaZ } = xForm()

  const [a, setA] = useState('') // input from form

  useEffect(() => {
      if (document && document.a){ 
        setA(document.a)
      }
    }, [document])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleClick = (e) => {
    e.preventDefault()
    navigate(`/projectoverview/${jID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // document is a project doc
    // for each member of the WG of project document
    document.WG.forEach( async (memb) => {
      // query the coll of USERS with condition that the uID == memb.oUID
      const q = query(collection(db, "USERS"), where("uID", "==", memb.oUID));
      // now get the docs collectively
      const querySnapshot = await getDocs(q);
      // for each document in this snapshot 
      querySnapshot.forEach(async (ddoc) => {
        // ddoc.data() is never undefined for query doc snapshots
        // update the J arr of this ddoc entry which is a particular users document
        // update here only the a field of the doc with the right jID
        const updatedJ = ddoc.data().J.map((prj) => 
          prj.jID === jID ? { ...prj, ...{
              a:a
            }} : prj
          )
        // update the users collection
        await updateDoc(doc(db, "USERS", ddoc.id), {
          J: updatedJ,
        });
      });
    })

    // for each member of the I of project document
    document.I.forEach( async (memb) => {
      // query the coll of USERS with condition that the uID == memb.oUID
      const q = query(collection(db, "USERS"), where("uID", "==", memb.oUID));
      // now get the docs collectively
      const querySnapshot = await getDocs(q);
      // for each document in this snapshot 
      querySnapshot.forEach(async (ddoc) => {
        // ddoc.data() is never undefined for query doc snapshots
        // update the I arr of this ddoc entry which is a particular users document
        // update here only the a field of the doc with the right jID
        const updatedI = ddoc.data().I.map((prj) => 
          prj.jID === jID ? { ...prj, ...{
              a:a
            }} : prj
          )
        // update the users collection
        await updateDoc(doc(db, "USERS", ddoc.id), {
          I: updatedI,
        });
      });
    })
    
    // update the I list of project doc
    const updatedProjI = document.I.map((prj) => 
      prj.jID === jID ? { ...prj, ...{
          a:a
        }} : prj
      )
    // update for the main Projects collection, a field
    const updates = { 
      a,
      I:updatedProjI
    }
    await updateDocument(jID, updates)

    if (!response.error) {
      notify()
      navigate(`/workspace`)
    }
  }

  return (
    <>
      {/** This is allowed only when the user is the initiator of this project */}
      {document.i !== user.uid && 
      <>
        <div className="justify-center text-center py-5">
          <HeadingH3Centered heading={"You do not have the rights to edit this field!"} />
        </div>
        <div className="pt-3">
          <Link to={`/projectoverview/${jID}`} className={LINK_STYLE_3}>
            Back
          </Link>
        </div>
      </>
      }
      {document.i === user.uid && 
        <>
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
                Edit Project Title
              </div>
            </div>

            <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <div className='mt-2'>
                    <div className='font-body font-medium tracking-wide leading-6'
                        dangerouslySetInnerHTML={{ __html: xFormXaZ(a) }} />
                  </div>
                  <div className="mt-2">
                    <input
                      id="editA"
                      name="editA"
                      type="text"
                      required
                      onChange={(e) => setA(e.target.value)} 
                      value={a}
                      className={INPUT_STYLE_1}
                    />
                  </div>
                </div>
                <div className="flex gap-3">
                  <button 
                    type='button'
                    onClick={handleClick}
                    className={BUTTON_CANCEL_STYLE_1}
                  >
                    Cancel
                  </button>
                  <button 
                    type='submit'
                    className={BUTTON_SUBMIT_STYLE_1}
                  >
                    Update
                  </button>
                </div>
                <div>
                  {error && <div>{error}</div>}
                </div>
              </form>

              <p className="text-center font-mono text-xs text-gray-500">
                Use with caution. This will change the title of the project.
                It could be that some of the participants may be confused!
              </p>

            </div>
          </div>
        </>
      }
    </>
  )
}
