
export default function NavbarBottomProvisional() {

  return (
    <>
      <div className='py-5'>
        &nbsp;
      </div>
      <div className='grid sm:grid-cols-3 py-5'>

        <div className="px-4 sm:px-12 sm:col-span-1 pb-3">
          <hr className='pt-3' />
          <div className="flex justify-start">
            <div className='pb-1'>
            </div>
          </div>
        </div>
      
        <div className="px-4 sm:col-span-1 pb-3">
          <hr className='mr-5 pt-3' />
          
        </div>

        <div className="px-4 sm:px-4 sm:col-span-1 pb-3">
          <hr className='mr-5 pt-3' />

        </div>

      </div>
      <div className='py-5'>
        &nbsp;
      </div>
      <div className='py-5'>
        &nbsp;
      </div>
    </>
  )
}
