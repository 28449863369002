import sortArray from "sort-array"
import { useAuthContext } from "../../hooks/useAuthContext"
import ListItemXS from "../../components/ListItemXS"
import { LINK_STYLE_1 } from "../../components/Constants"
import TextMonoXSRed from "../../components/TextMonoXSRed"
import { Link } from "react-router-dom"
import { DividerVerticalIcon, GearIcon } from "@radix-ui/react-icons"
import TextBodyBoldRed from "../../components/TextBodyBoldRed"

export default function ZListProjectK({project, meInWG}) {
  const { user } = useAuthContext()

  return (
    <>
      {
        project.KNM.length > 0 &&
        sortArray(project.KNM, {by:"q", order:"asc"}).map(k => (
          <div key={k.kID} className='pt-1 px-1'>
            {
              k.wf.filter(w => w.uID === user.uid).length === 1 && // get the user
              k.wf.filter(w => w.uID === user.uid)[0].v && // check if this K is visible
              <div>
                <hr/>
                <div className="flex pt-2">
                  <ListItemXS />
                  {
                    k.q && 
                    <Link 
                      to={`/editprojectkq/${project.jID}/${k.kID}`} 
                      className={LINK_STYLE_1}
                    > 
                      <TextMonoXSRed text={k.q} />
                    </Link>
                  }

                  {
                    meInWG.r7 && 
                    <div className="flex">
                      <DividerVerticalIcon />
                      <Link 
                        to={`/managek/${project.jID}/${k.kID}`} 
                        className={LINK_STYLE_1}
                      > 
                        <GearIcon />
                      </Link>
                    </div>
                  }
                </div>

                  {
                    k.a && 
                    <div className="flex gap-3 px-1">
                      <Link 
                        to={`/blockoverview/${project.jID}/${project.a}/${k.kID}`} className={LINK_STYLE_1}> 
                        <TextBodyBoldRed text={k.a} />
                      </Link>
                    </div>
                  }
              </div>
            }
          </div>
        ))
      }
    </>
  )
}