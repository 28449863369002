import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// pages
import Home from './pages/home/Home'
import Navbar from './components/Navbar'
import NavbarUser from './components/NavbarUser'
import NavbarBottom from './components/NavbarBottom'
import Readings from './pages/readings/Readings'
import Login from './pages/zugang/Login'
import Signup from './pages/zugang/Signup'
import Workspace from './pages/workspace/Workspace'
import EditProjectQ from './pages/workspace/EditProjectQ'
import Profile from './pages/profile/Profile'
import CollaborationReq1 from './pages/colleagues/CollaborationReq1'
import CollaborationReq2 from './pages/colleagues/CollaborationReq2'
import CollaborationReq3 from './pages/colleagues/CollaborationReq3'
import GoToStage01 from './pages/colleagues/GoToStage01'
import GoToStage02 from './pages/colleagues/GoToStage02'
import GoToStage03 from './pages/colleagues/GoToStage03'
import GoToStage04 from './pages/colleagues/GoToStage04'
import GoToStage05 from './pages/colleagues/GoToStage05'
import GoToStage06 from './pages/colleagues/GoToStage06'
import GoToStage07 from './pages/colleagues/GoToStage07'
import GoToStage08 from './pages/colleagues/GoToStage08'
import GoToStage09 from './pages/colleagues/GoToStage09'
import GoToStage10 from './pages/colleagues/GoToStage10'
import GoToStage11 from './pages/colleagues/GoToStage11'
import GoToStage12 from './pages/colleagues/GoToStage12'
import GoToStage13 from './pages/colleagues/GoToStage13'
import GoToStage14 from './pages/colleagues/GoToStage14'
import InitiateProject from './pages/projects/InitiateProject'
import ProjectOverview from './pages/projects/ProjectOverview'
import EditProjectName from './pages/projects/EditProjectName'
import WGInviteStep1 from './pages/projects/WGInviteStep1'
import WGInviteStep2 from './pages/projects/WGInviteStep2'
import WGInviteStep3 from './pages/projects/WGInviteStep3'
import WGInviteStep4 from './pages/projects/WGInviteStep4'
import ProjectWGDetails from './pages/projects/ProjectWGDetails'
import EditProjectKq from './pages/projects/EditProjectKq'
import ManageK from './pages/projects/ManageK'
import EditBlockTitle from './pages/blocks/EditBlockTitle'
import ManageKMakeInvisible from './pages/projects/ManageKMakeInvisible'
import ManageKMakeVisible from './pages/projects/ManageKMakeVisible'
import GetBlockJSON from './pages/blocks/GetBlockJSON'
import BlockOverview from './pages/blocks/BlockOverview'
import CreateBlock from './pages/blocks/CreateBlock'
import CreateNodehTmTiF from './pages/nodes/CreateNodehTmTiF'
import EditNodeYx from './pages/nodes/EditNodeYx'
import EditNodeYy from './pages/nodes/EditNodeYy'
import CreateNodehTmFiF from './pages/nodes/CreateNodehTmFiF'
import EditNodehTmTiFq from './pages/nodes/EditNodehTmTiFq'
import CreateNodehFmTiF from './pages/nodes/CreateNodehFmTiF'
import CreateNodehFmTiT from './pages/nodes/CreateNodehFmTiT'
import CreateNodehFmFiF from './pages/nodes/CreateNodehFmFiF'
import CreateNodehFmFiT from './pages/nodes/CreateNodehFmFiT'
import DeleteNodehFmTFiFYes from './pages/nodes/DeleteNodehFmTFiFYes'
import DeleteNodehFmTFiF from './pages/nodes/DeleteNodehFmTFiF'
import DeleteNodehFmTFiT from './pages/nodes/DeleteNodehFmTFiT'
import DeleteNodehFmTFiTYes from './pages/nodes/DeleteNodehFmTFiTYes'
import DeleteNodehTmFiFYes from './pages/nodes/DeleteNodehTmFiFYes'
import DeleteNodehTmFiF from './pages/nodes/DeleteNodehTmFiF'
import DeleteNodehTmTiF from './pages/nodes/DeleteNodehTmTiF'
import DeleteNodehTmTiFYes from './pages/nodes/DeleteNodehTmTiFYes'
import RenderNodePage from './pages/nodes/RenderNodePage'
import RenderNodePageClone from './pages/nodes/RenderNodePageClone'
import ImportNodehTmTiF1 from './pages/nodes/ImportNodehTmTiF1'
import ImportNodehTmTiF2 from './pages/nodes/ImportNodehTmTiF2'
import CreateNodehTmTiFYxMultiple from './pages/nodes/CreateNodehTmTiFYxMultiple'
import NavbarBottomProvisional from './components/NavbarBottomProvisional'
import IngressNodes from './pages/nodes/IngressNodes'
import EditNodeYb from './pages/nodes/EditNodeYb'


function App() {
  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      { authIsReady && (
        <BrowserRouter>
          {!user && <Navbar />}
          {user && <NavbarUser />}
          <div>
            <Routes>
              <Route path='/' element={ <Home /> } />
              <Route path='/login' element={user ? <Navigate to="/workspace" /> : <Login />} />
              <Route path='/signup' element={user ? <Navigate to="/workspace" /> : <Signup />} />
              <Route path='/readings' element={ <Readings /> } />
              <Route path='/workspace' element={user ? <Workspace /> : <Navigate to="/login" />} />
              <Route path='/profile' element={user ? <Profile /> : <Navigate to="/login" />} />

              <Route path='/editprojectq/:jID' element={user ? <EditProjectQ /> : <Navigate to="/login" />} />

              <Route path='/collaborationreq1' element={user ? <CollaborationReq1 /> : <Navigate to="/login" />} />
              <Route path='/collaborationreq2/:email' element={user ? <CollaborationReq2 /> : <Navigate to="/login" />} />
              <Route path='/collaborationreq3/:pongUID' element={user ? <CollaborationReq3 /> : <Navigate to="/login" />} />

              <Route path='/gotostage01/:oUID' element={user ? <GoToStage01 /> : <Navigate to="/login" />} />
              <Route path='/gotostage02/:oUID' element={user ? <GoToStage02 /> : <Navigate to="/login" />} />
              <Route path='/gotostage03/:oUID' element={user ? <GoToStage03 /> : <Navigate to="/login" />} />
              <Route path='/gotostage04/:oUID' element={user ? <GoToStage04 /> : <Navigate to="/login" />} />
              <Route path='/gotostage05/:oUID' element={user ? <GoToStage05 /> : <Navigate to="/login" />} />
              <Route path='/gotostage06/:oUID' element={user ? <GoToStage06 /> : <Navigate to="/login" />} />
              <Route path='/gotostage07/:oUID' element={user ? <GoToStage07 /> : <Navigate to="/login" />} />
              <Route path='/gotostage08/:oUID' element={user ? <GoToStage08 /> : <Navigate to="/login" />} />
              <Route path='/gotostage09/:oUID' element={user ? <GoToStage09 /> : <Navigate to="/login" />} />
              <Route path='/gotostage10/:oUID' element={user ? <GoToStage10 /> : <Navigate to="/login" />} />
              <Route path='/gotostage11/:oUID' element={user ? <GoToStage11 /> : <Navigate to="/login" />} />
              <Route path='/gotostage12/:oUID' element={user ? <GoToStage12 /> : <Navigate to="/login" />} />
              <Route path='/gotostage13/:oUID' element={user ? <GoToStage13 /> : <Navigate to="/login" />} />
              <Route path='/gotostage14/:oUID' element={user ? <GoToStage14 /> : <Navigate to="/login" />} />

              <Route path='/initiateproject' element={user ? <InitiateProject /> : <Navigate to="/login" />} />
              <Route path='/projectoverview/:jID' element={user ? <ProjectOverview /> : <Navigate to="/login" />} />
              <Route path='/editprojectname/:jID' element={user ? <EditProjectName /> : <Navigate to="/login" />} />
              <Route path='/editprojectkq/:jID/:kID' element={user ? <EditProjectKq /> : <Navigate to="/login" />} />

              <Route path='/wginvitestep1/:jID' element={user ? <WGInviteStep1 /> : <Navigate to="/login" />} />
              <Route path='/wginvitestep2/:jID/:oUID' element={user ? <WGInviteStep2 /> : <Navigate to="/login" />} />
              <Route path='/wginvitestep3/:jID' element={user ? <WGInviteStep3 /> : <Navigate to="/login" />} />
              <Route path='/wginvitestep4/:jID' element={user ? <WGInviteStep4 /> : <Navigate to="/login" />} />

              <Route path='/projectwgdetails/:jID' element={user ? <ProjectWGDetails /> : <Navigate to="/login" />} />

              <Route path='/managek/:jID/:kID' element={user ? <ManageK /> : <Navigate to="/login" />} />
              <Route path='/managekmakevisible/:jID/:kID/:uID' element={user ? <ManageKMakeVisible /> : <Navigate to="/login" />} />
              <Route path='/managekmakeinvisible/:jID/:kID/:uID' element={user ? <ManageKMakeInvisible /> : <Navigate to="/login" />} />

              <Route path='/blockoverview/:jID/:jA/:kID' element={user ? <BlockOverview /> : <Navigate to="/login" />} />
            
              <Route path='/createblock/:jID' element={user ? <CreateBlock /> : <Navigate to="/login" />} />

              <Route path='/ingressnodes/:jID/:jA/:kID' element={user ? <IngressNodes /> : <Navigate to="/login" />} />

              <Route path='/createnodehtmtif/:jID/:jA/:kID' element={user ? <CreateNodehTmTiF /> : <Navigate to="/login" />} />
              <Route path='/createnodehtmtifyxmultiple/:jID/:jA/:kID' element={user ? <CreateNodehTmTiFYxMultiple /> : <Navigate to="/login" />} />

              <Route path='/importnodehtmtif1/:jID/:jA/:kID' element={user ? <ImportNodehTmTiF1 /> : <Navigate to="/login" />} />
              <Route path='/importnodehtmtif2/:jID/:jA/:kID/:impKID/:impNID' element={user ? <ImportNodehTmTiF2 /> : <Navigate to="/login" />} />

              <Route path='/createnodehfmtif/:jID/:jA/:kID/:pID' element={user ? <CreateNodehFmTiF /> : <Navigate to="/login" />} />
              <Route path='/createnodehfmtit/:jID/:jA/:kID/:pID' element={user ? <CreateNodehFmTiT /> : <Navigate to="/login" />} />

              <Route path='/createnodehtmfif/:jID/:jA/:kID/:pID' element={user ? <CreateNodehTmFiF /> : <Navigate to="/login" />} />
              <Route path='/createnodehfmfif/:jID/:jA/:kID/:pID' element={user ? <CreateNodehFmFiF /> : <Navigate to="/login" />} />
              <Route path='/createnodehfmfit/:jID/:jA/:kID/:pID' element={user ? <CreateNodehFmFiT /> : <Navigate to="/login" />} />

              <Route path='/rendernodepage/:jID/:jA/:kID/:nID' element={user ? <RenderNodePage /> : <Navigate to="/login" />} />
              <Route path='/rendernodepageclone/:jID/:jA/:kID/:nID' element={user ? <RenderNodePageClone /> : <Navigate to="/login" />} />

              <Route path='/deletenodehfmtfif/:jID/:jA/:kID/:nID' element={user ? <DeleteNodehFmTFiF /> : <Navigate to="/login" />} />
              <Route path='/deletenodehfmtfit/:jID/:jA/:kID/:nID' element={user ? <DeleteNodehFmTFiT /> : <Navigate to="/login" />} />
              <Route path='/deletenodehtmfif/:jID/:jA/:kID/:nID' element={user ? <DeleteNodehTmFiF /> : <Navigate to="/login" />} />
              <Route path='/deletenodehtmtif/:jID/:jA/:kID/:nID' element={user ? <DeleteNodehTmTiF /> : <Navigate to="/login" />} />
              <Route path='/deletenodehfmtfifyes/:jID/:jA/:kID/:nID' element={user ? <DeleteNodehFmTFiFYes /> : <Navigate to="/login" />} />
              <Route path='/deletenodehfmtfityes/:jID/:jA/:kID/:nID' element={user ? <DeleteNodehFmTFiTYes /> : <Navigate to="/login" />} />
              <Route path='/deletenodehtmfifyes/:jID/:jA/:kID/:nID' element={user ? <DeleteNodehTmFiFYes /> : <Navigate to="/login" />} />
              <Route path='/deletenodehtmtifyes/:jID/:jA/:kID/:nID' element={user ? <DeleteNodehTmTiFYes /> : <Navigate to="/login" />} />

              <Route path='/editnodeyx/:jID/:jA/:kID/:nID' element={user ? <EditNodeYx /> : <Navigate to="/login" />} />
              <Route path='/editnodeyy/:jID/:jA/:kID/:nID' element={user ? <EditNodeYy /> : <Navigate to="/login" />} />
              <Route path='/editnodeyb/:jID/:jA/:kID/:nID' element={user ? <EditNodeYb /> : <Navigate to="/login" />} />
              <Route path='/editnodehtmtifq/:jID/:jA/:kID/:nID' element={user ? <EditNodehTmTiFq /> : <Navigate to="/login" />} />

              <Route path='/editblocktitle/:jID/:kID' element={user ? <EditBlockTitle /> : <Navigate to="/login" />} />

              <Route path='/getblockjson/:jID/:jA/:kID' element={user ? <GetBlockJSON /> : <Navigate to="/login" />} />


            </Routes>
          </div>
          <NavbarBottomProvisional />
        </BrowserRouter>
      )}
    </div>
  )
}

export default App;
