
// given a pID i.e. nID of the primary node
// render all nodes that are its secondary

import sortArray from "sort-array"
import ZRenderNodeYx from "./ZRenderNodeYx"
import ZRenderNodeYy from "./ZRenderNodeYy"
import ZRenderNodeYb from "./ZRenderNodeYb"
import { Link } from "react-router-dom"
import { DividerVerticalIcon, DotFilledIcon, Pencil2Icon, TrashIcon } from "@radix-ui/react-icons"
import { LINK_STYLE_3 } from "../../components/Constants"

// and belong to the right panel iF
export default function ZRenderhFmFiFNodes({jID, jA, kID, N, pID, showMenu}) {

  const hmiNodes = N.filter(nde => !nde.h && !nde.m && !nde.i && 
    nde.pID === pID)
  
  return (
    <>
      {hmiNodes && 
        sortArray(hmiNodes, {by:"q", order:"asc"}).map(nd => (
          <div key={nd.nID}>
            {nd.g === "Yx" && 
              <div>
                {showMenu && 
                  <div className="flex">
                    <Link to={`/editnodeyx/${jID}/${jA}/${kID}/${nd.nID}`} 
                      className={LINK_STYLE_3}
                    >
                      <Pencil2Icon />
                    </Link>
                    <DividerVerticalIcon />
                    <Link to={`/deletenodehfmtfif/${jID}/${jA}/${kID}/${nd.nID}`} 
                      className={LINK_STYLE_3}
                    >
                      <TrashIcon />
                    </Link>
                  </div>
                }
                <div className="flex leading-5 text-sm">
                  {!showMenu && 
                  <DotFilledIcon />}
                  <ZRenderNodeYx node={nd} />
                </div>
              </div>
            }
            {nd.g === "Yy" && <ZRenderNodeYy node={nd} />}
            {nd.g === "Yb" && <ZRenderNodeYb node={nd} />}
          </div>
        ))
      }
    </>
  )
}