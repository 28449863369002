import GetWGRole from "../../components/GetWGRole";
import HeadingH3Mono from "../../components/HeadingH3Mono";
import ListItemXS from "../../components/ListItemXS";
import TextMonoBoldXS from "../../components/TextMonoBoldXS";
import TextMonoXS from "../../components/TextMonoXS";

export default function ZInviteList({inviteList}) {

  // inviteList = [{ },...]
  // t timestamp
  // jID id of the project
  // a: display name title of the project
  // iUID ping uID the person sending the invite
  // di: display name of the person
  // ei: email
  // oUID pong uID the person being invited
  // do: name
  // eo: email
  // y: yes accepted
  // n: no declined
  // r9-r1: t/f roles he is supposed to do

  return (
    <>
      {inviteList.length > 0 && 
        <HeadingH3Mono heading={"Sent Invitations"} />
      }
      {
        inviteList && inviteList.length > 0 && inviteList.map(inv => (
          <div key={inv.oUID} >
            {
              !inv.y && 
              <div className='font-mono pt-1 pb-3 px-2'>
                <div className='flex'>
                  <ListItemXS />
                  <TextMonoXS text={"To:"} />
                  <TextMonoBoldXS text={inv.do} />
                </div>
                <div className='flex'>
                  <ListItemXS />
                  <TextMonoXS text={"By:"} />
                  <TextMonoBoldXS text={inv.di} />
                </div>
                <div className='flex'>
                  <ListItemXS />
                  <TextMonoXS text={"Role:"} />
                  <TextMonoBoldXS text={<GetWGRole wgMemb={inv} />} />
                </div>
                {inv.n && 
                  <div className="px-2">
                    <TextMonoBoldXS text={"declined!"} />
                  </div>
                }
                <div className="pt-1">
                  <hr/>
                </div>
              </div>
            }
          </div>
        ))
      }
    </>
  )
}