import { useEffect, useState } from 'react';
import { ERROR_STYLE_1, LINK_STYLE_1, LOADING_STYLE_1 } from '../../components/Constants';
import { useDocument } from '../../hooks/useDocument'
import { useNavigate, useParams } from 'react-router-dom';
import NavbarProjectBlock from '../../components/NavbarProjectBlock';
import { CheckIcon, CopyIcon, DividerVerticalIcon, EyeClosedIcon, EyeOpenIcon, MaskOffIcon, MaskOnIcon, ThickArrowLeftIcon, ThickArrowRightIcon } from '@radix-ui/react-icons';
import ListItemXS from '../../components/ListItemXS';
import ZRenderNodeYx from './ZRenderNodeYx';
import ZRenderNodeYy from './ZRenderNodeYy';
import ZRenderNodeYb from './ZRenderNodeYb';
import ZRenderhFmTiFNodes from './ZRenderhFmTiFNodes';
import ZRenderhFmTiTNodes from './ZRenderhFmTiTNodes';
import ZMenuhTmFiF from '../blocks/ZMenuhTmFiF';
import ZRenderhTmFiFNodes from './ZRenderhTmFiFNodes';
import ZRenderhFmFiFNodes from './ZRenderhFmFiFNodes';
import ZRenderhFmFiTNodes from './ZRenderhFmFiTNodes';
import ZMenuhTmTiFNodePage from '../blocks/ZMenuhTmTiFNodePage';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';

// nID unique node ID 
// t: is last update
// q: for sequencing
// h: true as it is pradhAna node
// m: true if left panel
// i: false if not individual / personal
// g: Yx / Yy / Yb types or Genus of node
// x: MAIN text HK
// y: Main text in eng mixed
// b: main bib title
// c: main catalog title field
// pID and uID are not needed here

export default function RenderNodePageClone() {
  const { jID, jA, kID, nID } = useParams()
  // vux prev v current u and next x
  const { error, document } = useDocument('KNM', kID)
  const navigate = useNavigate()

  const [showMenu, setShowMenu] = useState(true)
  const [showSubsidiaries, setShowSubsidiaries] = useState(true)
  const [isCopied, handleCopy] = useCopyToClipboard(3000)

  const [HMi, setHMi] = useState([]) // h:T m:T i:F nodes
  const [Hmi, setHmi] = useState([]) // h:T m:F i:F nodes
  const [currentNode, setCurrentNode] = useState({}) // h:T m:F i:F nodes
  const [prevnID, setPrevnID] = useState('') // 
  const [nextnID, setNextnID] = useState('') // 
  
  useEffect(() => {
    if (document){
      const HmiArr = document.N.filter(nde => nde.h && !nde.m && !nde.i)
      setHmi(HmiArr)

      const HMiArr = document.N.filter(nde => nde.h && nde.m && !nde.i)
      const HMiArrThisNode = document.N.filter(nde => nde.h && nde.m && !nde.i && nde.nID === nID)
      setHMi(HMiArr)
      if (HMiArrThisNode.length ===1) {
        setCurrentNode(HMiArrThisNode[0])

        const currIdx = HMiArr.map(n => n.nID).indexOf(nID)
        setPrevnID(HMiArr[(currIdx+HMiArr.length-1)%HMiArr.length].nID)
        setNextnID(HMiArr[(currIdx+1)%HMiArr.length].nID)
      }

    }
  }, [document, nID])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleNextNode = (e) => {
    e.preventDefault()
    navigate(`/rendernodepage/${jID}/${jA}/${kID}/${nextnID}`)
  }
  const handlePrevNode = (e) => {
    e.preventDefault()
    navigate(`/rendernodepage/${jID}/${jA}/${kID}/${prevnID}`)
  }

  return (
    <>
      <NavbarProjectBlock jID={jID} jA={jA} kID={kID} kA={document.a} />

      <div className="grid grid-cols-3 bg-white font-body">
        <div className="flex justify-start gap-3 px-4 sm:px-12  py-3">
          {!showMenu && 
            <div onClick={()=>{setShowMenu(true)}} className={LINK_STYLE_1} >
              <MaskOnIcon />
            </div>
          }
          {showMenu && 
            <div onClick={()=>{setShowMenu(false)}} className={LINK_STYLE_1}>
              <MaskOffIcon />
            </div>
          }
          {!showSubsidiaries && 
            <div onClick={()=>{setShowSubsidiaries(true)}} className={LINK_STYLE_1} >
              <EyeClosedIcon />
            </div>
          }
          {showSubsidiaries && 
            <div onClick={()=>{setShowSubsidiaries(false)}} className={LINK_STYLE_1}>
              <EyeOpenIcon />
            </div>
          }
        </div>
        <div className="flex justify-center">
          <div onClick={handlePrevNode} className={LINK_STYLE_1}>
            <ThickArrowLeftIcon />
          </div>
          <DividerVerticalIcon />
          <div onClick={handleNextNode} className={LINK_STYLE_1}>
            <ThickArrowRightIcon />
          </div>
        </div>
        <div className="flex justify-end pr-4 sm:pr-12">
          <span onClick={() => handleCopy(kID+"/"+nID)}>
            { isCopied ? 
                <div className='flex text-abhi-red'>
                  <CheckIcon />
                </div> : 
                <div className='flex gap-1 text-abhi-red'>
                  <CopyIcon />
                </div>
            }
          </span>
        </div>
      </div>

      <div>
        {/** Display h:T m:T i:F node */}
        {HMi && currentNode &&
          <div className='grid sm:grid-cols-3 gap-3'>
            <div className="px-4 sm:px-12 sm:col-span-2">
              {showMenu && 
                <ZMenuhTmTiFNodePage jID={jID} jA={jA} kID={kID} N={document.N} node={currentNode} />
              }
              <div className="flex">
                {!showMenu && 
                  <div className="mt-1">
                    <ListItemXS />
                  </div>
                }
                {currentNode.g === "Yx" && 
                  <div className="leading-8">
                    <ZRenderNodeYx node={currentNode} />
                  </div>
                }
                {currentNode.g === "Yy" && <ZRenderNodeYy node={currentNode} />}
                {currentNode.g === "Yb" && <ZRenderNodeYb node={currentNode} />}
              </div>
              <div className="px-4">
                {showSubsidiaries && 
                <ZRenderhFmTiFNodes jID={jID} jA={jA} kID={kID} N={document.N} pID={currentNode.nID} showMenu={showMenu} />
                }
              </div> 
              <div className="px-4">
                {showSubsidiaries &&
                <ZRenderhFmTiTNodes jID={jID} jA={jA} kID={kID} N={document.N} pID={currentNode.nID} showMenu={showMenu} />
                }
              </div>  
            </div>

            {/** right column */}
            <div className="px-4 sm:col-span-1">
              {Hmi && Hmi.filter(nd => nd.pID === currentNode.nID).length ===1 && 
              <div>
                {showMenu  && 
                  <ZMenuhTmFiF jID={jID} jA={jA} kID={kID} N={document.N} pID={currentNode.nID} />
                }
                <div>
                  {/** Display h:T m:F i:F node */}
                  <ZRenderhTmFiFNodes N={document.N} pID={currentNode.nID} showMenu={showMenu}/>
                </div>
                <div>
                  {showSubsidiaries &&
                  <div>
                    <ZRenderhFmFiFNodes jID={jID} jA={jA} kID={kID} N={document.N} 
                      pID={Hmi.filter(nd => nd.pID === currentNode.nID)[0].nID} showMenu={showMenu} />
                    <ZRenderhFmFiTNodes jID={jID} jA={jA} kID={kID} N={document.N} 
                      pID={Hmi.filter(nd => nd.pID === currentNode.nID)[0].nID} showMenu={showMenu} />
                  </div>
                  }
                </div> 
              </div>
              }
            </div>
    
          </div>
          
        }
      </div>
    </>
  )
}
