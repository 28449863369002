import { DividerVerticalIcon } from "@radix-ui/react-icons";
import ListItemXS from "../../components/ListItemXS";
import ListItemXSRed from "../../components/ListItemXSRed";
import TextMonoBoldXS from "../../components/TextMonoBoldXS";
import TextMonoXS from "../../components/TextMonoXS";
import { Link } from "react-router-dom";
import { LINK_STYLE_5 } from "../../components/Constants";
import TextMonoBoldXSTight from "../../components/TextMonoBoldXSTight";

export default function ZColleagueStatus({contact}) {
  return (
    <>
      <div className='px-1'>

        <div className='flex pt-3'>
          {!contact.i && // i was pinged
            <ListItemXS />
          }
          {contact.i && // i pinged
            <ListItemXSRed />
          }
          <div>
            <TextMonoBoldXS text={contact.d} />
          </div>
        </div>
          <div>
            <TextMonoXS text={contact.e} />
          </div>

        {/** 
          * 00: 
          * yT 
          * riF yoT noF xiF xoF Collaboration. 
          * riF yoT noF xiF xoF 03: iOpen oYes => yT Collaboration
          * =iT stop->07
          * =iF stop->11
        * */}

        {
          contact.y && 
          !contact.ri && 
          contact.yo && 
          !contact.no && 
          !contact.xi && 
          !contact.xo && 
          <>
            <TextMonoBoldXS text={"We collaborate"} />
            {
              contact.i && 
              <Link to={`/gotostage07/${contact.o}`} className={LINK_STYLE_5}>
                <TextMonoXS text={"End this collaboration"} />
              </Link>
            }
            {
              !contact.i && 
              <Link to={`/gotostage11/${contact.o}`} className={LINK_STYLE_5}>
                <TextMonoXS text={"End this collaboration"} />
              </Link>
            }
          </>
        }

        {/** 
          * yF 
          * riF yoF noF xiF xoF 01: initial request
          * =iT recall->02 stop->07
          * =iF accept->03 reject->05 stop->11
        */}
        {
          !contact.y && 
          !contact.ri && 
          !contact.yo && 
          !contact.no && 
          !contact.xi && 
          !contact.xo && 
          <>
            {
              contact.i && 
                <>
                <TextMonoBoldXS text={"I have sent a request"} />
                
                <div className="flex">
                  <Link to={`/gotostage02/${contact.o}`} className={LINK_STYLE_5}>
                      Revoke
                  </Link>
                  <DividerVerticalIcon />
                  <Link to={`/gotostage07/${contact.o}`} className={LINK_STYLE_5}>
                    Stop any communication
                  </Link>
                </div>
                </>
            }
            {
              !contact.i && 
              <>
                <TextMonoBoldXS text={"sends a request"} />
                <div className="flex">
                  <Link to={`/gotostage03/${contact.o}`} className={LINK_STYLE_5}>
                      Accept
                  </Link>
                  <DividerVerticalIcon />
                  <Link to={`/gotostage05/${contact.o}`} className={LINK_STYLE_5}>
                      Reject
                  </Link>
                </div>
                <div className="flex">
                  <Link to={`/gotostage11/${contact.o}`} className={LINK_STYLE_5}>
                      Stop any communication
                  </Link>
                </div>
              </>
            }
          </>
        }


        {/**
           * yF 
           * riT yoF noF xiF xoF 02: iRecall oUndecided
           * =iT recall->01 stop->08
           * =iF accept->04 reject->06 stop->12
          */}

        {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            !contact.no && 
            !contact.xi && 
            !contact.xo && 
            <>
              {
                contact.i && 
                <>
                <TextMonoBoldXS text={"I sent a request but then took it back."} />
                <div className="flex">
                  <Link to={`/gotostage01/${contact.o}`} className={LINK_STYLE_5}>
                    <TextMonoXS text={"Request again"} />
                  </Link>
                  <DividerVerticalIcon />
                  <Link to={`/gotostage08/${contact.o}`} className={LINK_STYLE_5} >
                    <TextMonoXS text={"Stop any communication"} />
                  </Link>
                </div>
                </>
              }
              {
                !contact.i && 
                <>
                  <TextMonoBoldXSTight text={"sent a request but then took it back."} />
                  <div className="flex">
                    <TextMonoBoldXSTight text={"I wait"} />
                    <DividerVerticalIcon />
                    
                    {/*<Link to={`/gotostage04/${contact.o}`} className={LINK_STYLE_5}>
                        Accept
                        </Link>*/}
                    <Link to={`/gotostage06/${contact.o}`} className={LINK_STYLE_5}>
                        <TextMonoXS text={"Reject"} />
                    </Link>
                    <DividerVerticalIcon />
                    <Link to={`/gotostage12/${contact.o}`} className={LINK_STYLE_5}>
                        <TextMonoXS text={"Stop any communication"} />
                    </Link>
                  </div>
                </>
              }
            </>
          }


          {/**
           * yF 
           * riT yoT noF xiF xoF 04: iRecall oYes
           * =iT recall->03 stop->09
           * =iF nowreject->06 stop->12
           */}

          {
            !contact.y && 
            contact.ri && 
            contact.yo && 
            !contact.no && 
            !contact.xi && 
            !contact.xo && 
            <>
              {
                contact.i && 
                <>
                  I sent a request but took it back |&nbsp;
                  {contact.d} has accepted my request
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage03/${contact.o}`}
                   className={LINK_STYLE_5}
                  >
                      Agree and collaborate
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage09/${contact.o}`}
                   className={LINK_STYLE_5}
                  >
                      Stop any communication
                  </Link>
                </>
                
              }
              {
                !contact.i && 
                <>
                  <TextMonoBoldXS text={"Requested, but later revoked"} />
                  <TextMonoBoldXS text={"I accepted"} />
                  <Link to={`/gotostage06/${contact.o}`} className={LINK_STYLE_5}>
                      Reject now
                  </Link>
                  <DividerVerticalIcon/>
                  <Link to={`/gotostage12/${contact.o}`} className={LINK_STYLE_5}>
                    Stop any communication
                  </Link>
                </>
              }
            </>
          }

          {/**
           * yF 
           * riF yoF noT xiF xoF 05: iOpen oNo
           * =iT recall->06 stop->10
           * =iF accept->03 stop->11
           */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            contact.no && 
            !contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <>
                  <TextMonoBoldXSTight text={"I sent a request, but "+contact.d+" rejected."
                  } />
                  <Link to={`/gotostage06/${contact.o}`} className={LINK_STYLE_5}>
                    <TextMonoXS text={"I also take back my request"} />
                  </Link>

                  <Link to={`/gotostage10/${contact.o}`} className={LINK_STYLE_5}>
                    <TextMonoXS text={"Stop any communication"} />
                  </Link>
                </>
              }
              {
                !contact.i && 
                <>
                  <TextMonoBoldXSTight text={"sent a request, I rejected"} />
                  <div className="flex">
                    <Link to={`/gotostage03/${contact.o}`} className={LINK_STYLE_5}>
                        Accept now
                    </Link>
                  </div>
                  <div className="flex">
                    <Link to={`/gotostage11/${contact.o}`} className={LINK_STYLE_5}>
                        Stop any communication
                    </Link>
                  </div>
                </>
              }
            </span>
          }


          {/**
           * yF 
           * riT yoF noT xiF xoF 06: iRecall oNo
           * =iT revoke->05 stop->10
           * =iF accept->04 stop->12
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            contact.no && 
            !contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <>
                  <TextMonoBoldXSTight text={"I had sent a request but later took it back. And "+contact.d+" has rejected my request."} />
                  <Link to={`/gotostage05/${contact.o}`} className={LINK_STYLE_5}>
                    <TextMonoXS text={"I request again"} />
                  </Link>
                  <Link to={`/gotostage10/${contact.o}`} className={LINK_STYLE_5} >
                    <TextMonoXS text={"Stop any communication"} />
                  </Link>
                </>
                
              }
              {
                !contact.i && 
                <>
                  <TextMonoBoldXS text={"Requested but revoked. I also rejected."} />
                  <div className="flex">
                    <TextMonoXS text={"Wait"} />
                    <DividerVerticalIcon />
                    <Link to={`/gotostage12/${contact.o}`} className={LINK_STYLE_5} >
                      <TextMonoXS text={"Stop any communication"} />
                    </Link>
                  </div>
                </>
              }
            </span>
          }

          {/**
           * yF 
           * riF yoF noF xiT xoF 07: iOpen oUndecided iStopped
           * =iT restart->01
           * =iF 
           */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            !contact.no && 
            contact.xi && 
            !contact.xo && 
            <>
              {
                contact.i && 
                <>
                  <TextMonoBoldXSTight text={"I had sent a request but later stopped any communication. "+contact.d+" is undecided."} />
                  <Link to={`/gotostage01/${contact.o}`} className={LINK_STYLE_5} >
                    <TextMonoXS text={"I request again"} />
                  </Link>
                </>
              }
              {
                !contact.i && 
                <>
                  <TextMonoBoldXSTight text={"sent a request but later stopped any communication. I may reconsider in case "+contact.d+" requests again."} />
                </>
              }
            </>
          }

          {/**
           * yF 
           * riT yoF noF xiT xoF 08: iRecall oUndecided iStopped
           * =iT restart->01
           * =iF 
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            !contact.no && 
            contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <>
                  <TextMonoBoldXSTight text={"I had sent a request but later recalled it and even stopped any communication. "+contact.d+" is undecided."} />
                  <Link to={`/gotostage01/${contact.o}`} className={LINK_STYLE_5} >
                    <TextMonoXS text={"I request again"} />
                  </Link>
                </>
              }
              {
                !contact.i && 
                <>
                  <TextMonoBoldXSTight text={"sent a request but later recalled and stopped any communication. I was undecided and will decide in case "+contact.d+" requests again."} />
                </>
              }
            </span>
          }


          {/**
           * yF 
           * riT yoT noF xiT xoF 09: iRecall oYes iStopped
           * =iT revoke and collaborate->03
           * =iF 
           */}

{
            !contact.y && 
            contact.ri && 
            contact.yo && 
            !contact.no && 
            contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later recalled it and even stopped any communication |&nbsp;
                  <b>{contact.d}</b> had accepted my request |&nbsp;
                  <Link to={`/gotostage03/${contact.o}`}
                   className={LINK_STYLE_5}
                  >
                      I now collaborate
                  </Link>
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later recalled and stopped any communication |&nbsp;
                  I however accepted and a collaboration may result in case <b>{contact.d}</b> is ready and takes the initiative
                </span>
              }
            </span>
          }

          {/**
           * yF 
           * riT yoF noT xiT xoF 10: iRecall oNo iStopped
           * =iT restart->06 
           * =iF 
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            contact.no && 
            contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later recalled it and even stopped any communication |&nbsp;
                  <b>{contact.d}</b> has also rejected my request |&nbsp;
                  <Link to={`/gotostage06/${contact.o}`}
                   className={LINK_STYLE_5}
                  >
                      I try a restart
                  </Link>
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later recalled and stopped any communication
                  &nbsp;|&nbsp;
                  I also rejected and in case <b>{contact.d}</b> tries again, I may consider further
                </span>
              }
            </span>
          }

          {/**
           * yF 
           * riF yoF noT xiF xoT 11: iOpen oNo oStopped
           * =iT 
           * =iF restart->01
           */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            contact.no && 
            !contact.xi && 
            contact.xo && 
            <span>
              {
                contact.i && 
                <>
                  <TextMonoBoldXSTight text={"I had sent a request. "+contact.d+" rejected & stopped any communication. In case "+contact.d+ " tries again, I may reconsider."} />
                </>
              }
              {
                !contact.i && 
                <>
                  <TextMonoBoldXS text={"Requested"} />
                  <TextMonoBoldXS text={"I rejected & stopped communication"} />
                  <Link to={`/gotostage01/${contact.o}`} className={LINK_STYLE_5} >
                    <TextMonoXS text={"I try a restart"} />
                  </Link>
                </>
              }
            </span>
          }

          {/**
           * yF 
           * riT yoF noT xiF xoT 12: iRecall oNo ididintStop oStopped
           * =iT 
           * =iF restart->02
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            contact.no && 
            !contact.xi && 
            contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later took it back | 
                  <b>{contact.d}</b> rejected my request and has stopped any communication |&nbsp;
                  In case <b>{contact.d}</b> tries again, I may consider further
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later took it back |&nbsp;
                  I rejected and stopped any communication |&nbsp;
                  <Link to={`/gotostage02/${contact.o}`}
                   className={LINK_STYLE_5}
                  >
                      I try a restart
                  </Link>
                </span>
              }
            </span>
          }
          

          {/**
           * yF 
           * riF yoF noT xiT xoT 13: iOpen oNo iStopped oStopped
           * =iT 
           * =iF restart->07
           */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            contact.no && 
            contact.xi && 
            contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later stopped any communication |&nbsp;
                  <b>{contact.d}</b> rejected my request and has stopped any communication
                  &nbsp;|&nbsp;
                  It is upto <b>{contact.d}</b> to take the initiative
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later stopped any communication |&nbsp;
                  I rejected and also stopped any communication |&nbsp;
                  <Link to={`/gotostage07/${contact.o}`}
                   className={LINK_STYLE_5}
                  >

                      I restart a communication

                  </Link>
                </span>
              }
            </span>
          }

           {/**
           * yF 
           * riT yoF noT xiT xoT 14: iRecall oNo oStopped
           * =iT 
           * =iF restart->08
           */}

{
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            contact.no && 
            contact.xi && 
            contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later took it back and stopped any communication |&nbsp;
                  <b>{contact.d}</b> rejected my request and has stopped any communication |&nbsp;
                  It is upto <b>{contact.d}</b> to take the initiative
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later took it back and stopped any communication |&nbsp;
                  I rejected and also stopped any communication |&nbsp;
                  <Link to={`/gotostage08/${contact.o}`}
                   className={LINK_STYLE_5}
                  >

                      I restart a communication

                  </Link>
                </span>
              }
            </span>
          }




      </div>
    </>
  )
}