export default function GetWGRole({wgMemb}) {
  
  return (
    <>
      {wgMemb.r9 && <>Owner</>}
      {!wgMemb.r9 && wgMemb.r7 && <>Manager</>}
      {!wgMemb.r9 && !wgMemb.r7 && wgMemb.r5 && <>Admin</>}
      {!wgMemb.r9 && !wgMemb.r7 && !wgMemb.r5 && wgMemb.r3 && <>Editor</>}
      {!wgMemb.r9 && !wgMemb.r7 && !wgMemb.r5 && !wgMemb.r3 && wgMemb.r1 && <>Reader</>}
    </>
  )
}