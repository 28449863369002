import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"
import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, INPUT_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Centered from "../../components/HeadingH3Centered"
import HeadingH2Centered from "../../components/HeadingH2Centered"

export default function EditProjectKq() {
  const { user } = useAuthContext()
  const { jID, kID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const { updateDocument, response } = useFirestore('Projects') 
  const navigate = useNavigate()
  const [q, setQ] = useState('')
  const [meInWG, setMeInWG] = useState({})
  const [block, setBlock] = useState({})

  // KNM: List of blocks
  useEffect(() => {
    if (document && document.KNM){ 
      let result = document.KNM.filter(obj => {
        return obj.kID === kID
      })
      if (result.length > 0) {
        setQ(result[0].q)
        setBlock(result[0])
      }
    }
    if (document && document.WG.length>0){ 
      let resultMe = document.WG.filter(obj => {
        return obj.oUID === user.uid
      })
      setMeInWG(resultMe[0])
    }
  }, [document, user.uid, kID])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleClick = (e) => {
    e.preventDefault()
    navigate(`/projectoverview/${jID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const newKNMs = document.KNM.map( knm => {
      if (knm.kID === kID) {
        return {...knm, q:q}
      } else {
        return {...knm}
      }
    })

    const updates = { 
      KNM: newKNMs
    }

    await updateDocument(jID, updates)
    if (!response.error) {
      navigate(`/projectoverview/${jID}`)
    }
  }

  return (
    <>
      {
        meInWG &&
        <>
          <div className="pt-5">
            <div className="font-mono font-bold">
              <HeadingH3Centered heading={"Edit Block Sequence"} />
            </div>
            <div className="font-body font-bold">
              <HeadingH2Centered heading={block.a} />
            </div>
          </div>

          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
            <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <input 
                  id="Q"
                  type="text" 
                  required
                  onChange={(e) => setQ(e.target.value)}
                  value={q}
                  className={INPUT_STYLE_1}
                  />
                <div className="flex gap-3">
                  <button 
                    type='button'
                    onClick={handleClick}
                    className={BUTTON_CANCEL_STYLE_1}
                    >
                    Cancel
                  </button>
                  <button 
                    type='submit'
                    className={BUTTON_SUBMIT_STYLE_1}
                    >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      }
    </>
  )
}
