export default function HeadingH3Centered({heading}) {
  
  return (
    <>
      <div className="flex mt-1 justify-center text-center leading-6">
        <div className="text-l tracking-tight text-gray-900">
          {heading}  
        </div>
      </div>
    </>
  )
}