import xForm from "../hooks/xForm"

export default function TextBodyBoldRed({text}) {
  const { xFormXaZ } = xForm()
  return (
    <>
      <div className="text-left text-l font-body font-bold text-abhi-red">
        <div className=''
          dangerouslySetInnerHTML={{ __html: xFormXaZ(text) }} 
        />
      </div>
    </>
  )
}