import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useFirestore } from "../../hooks/useFirestore"
import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Centered from "../../components/HeadingH3Centered"
import TextMonoBoldSMRed from "../../components/TextMonoBoldSMRed"

export default function ManageKMakeVisible() {
  // you need user for your role
    const { user } = useAuthContext()
    const { jID, kID, uID } = useParams()
    const { error, document } = useDocument('Projects', jID)
    const { updateDocument, response } = useFirestore('Projects')
    const navigate = useNavigate()
    const [meInWG, setMeInWG] = useState({})
    const [wf, setWf] = useState([])
  
    // first we need the wf array
    useEffect(() => {
      if (document && document.WG){ 
        let result = document.WG.filter(obj => {
          return obj.oUID === user.uid
        })
        setMeInWG(result[0])
      }
      if (document){
        const selectedK = document.KNM.filter(k => k.kID === kID)
        if (selectedK.length > 0) {
          setWf(selectedK[0].wf)
        }
      }
    }, [document, kID, user.uid])
  
  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/managek/${jID}/${kID}`)
  }
  
    const handleSubmit = async (e) => {
      e.preventDefault()
      // update the wf array
      // document is {KNM:[]}
      // KNM[kID] is {} and here {wf:[v:t/f]}
      const updatedWf = wf.map((w) => 
        w.uID === uID ? { ...w, ...{
            v:true // set v to true
          }} : w
        )
      // update one record of document.KNM
      const updatedKNM = document.KNM.map((k) => 
        k.kID === kID ? { ...k, ...{
            wf:updatedWf
          }} : k
        )
      const updates = { 
        KNM: updatedKNM
      }
  
      await updateDocument(jID, updates)
      if (!response.error && !response.error) {
        navigate(`/managek/${jID}/${kID}`)
      }
    }

  return (
    <>
      {meInWG.r7 &&
      <>
        <div className="py-5">
          <HeadingH3Centered heading={"You are about to make the block"} />
            <div className="font-mono font-bold">
              <HeadingH3Centered heading={document.KNM.filter(k => k.kID === kID)[0].a} />
            </div>
            <div className="flex justify-center text-center">
              <TextMonoBoldSMRed text={"VISIBLE"} />
            </div>
            <HeadingH3Centered heading={"to"} />
          <div className="flex justify-center gap-3 font-mono font-bold">
            <HeadingH3Centered heading={wf.filter(u => u.uID === uID)[0].d} />
          </div>
        </div>
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit}>
            <div className="flex gap-3">
              <button 
                type='button'
                onClick={handleCancel}
                className={BUTTON_CANCEL_STYLE_1}
              >
                Cancel
              </button>
              <button 
                type='submit'
                className={BUTTON_SUBMIT_STYLE_1}
              >
                Make Visible
              </button>
            </div>

          </form>
        </div>
      </>
      }
    </>
  )
}
