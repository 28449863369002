import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import { useDocumentA } from "../../hooks/useDocumentA";
import { useFirestore } from "../../hooks/useFirestore";
import { useFirestoreA } from "../../hooks/useFirestoreA";
import { BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, LINK_STYLE_3, LOADING_STYLE_1 } from "../../components/Constants";
import HeadingH3Centered from "../../components/HeadingH3Centered";
import GetWGRole from "../../components/GetWGRole";

// reject
// I.n : true
export default function WGInviteStep3() {
  const { user } = useAuthContext()
  const { jID } = useParams()

  const { error, document } = useDocument('USERS', user.uid)
  const { updateDocument, response } = useFirestore('USERS')
  // documentA is the project
  const { errorA, documentA } = useDocumentA('Projects', jID)
  const { updateDocumentA, responseA } = useFirestoreA('Projects')
  const navigate = useNavigate()

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  if (errorA) {return <div className={ERROR_STYLE_1}>{errorA}</div>}
  if (!documentA) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()

    // update my account
    const updatedArrMy = document.I.map((inv) => 
      inv.jID === jID ? { ...inv, ...{
        n:true,
        y:false
      }} : inv
    )

    const updatesMy = { 
      I: updatedArrMy // I Invite array
    }

    // update project entry
    const updatedArrIProj = documentA.I.map((inv) => 
      inv.oUID === user.uid ? { ...inv, ...{
        n:true,
        y:false
      }} : inv
    )

    const updatesProj = { 
      I: updatedArrIProj // I Invite array
    }

    // save updatesMy to my account
    await updateDocument(user.uid, updatesMy)
    // save updatesProj to proj
    await updateDocumentA(jID, updatesProj)
    if (!response.error && !responseA.error) {
      navigate('/workspace')
    }
  }

  return (
    <>
      <div className="py-5">
        <div className="flex justify-center">
          {document.I.length >0 && document.I.map(inv => (
            <div key={inv.jID} >
              {inv.jID === jID && !inv.y && !inv.n && 
                <>
                  <HeadingH3Centered heading={"You have been invited by"} />
                  <div className="font-mono font-bold">
                    <HeadingH3Centered heading={inv.di} />
                  </div>
                  <HeadingH3Centered heading={"to participate in the project"} />
                  <div className="flex justify-center gap-3 font-mono font-bold">
                    <HeadingH3Centered heading={documentA.a} />
                  </div>
                    <HeadingH3Centered heading={"as"} />
                  <div className="font-mono font-bold">
                    <HeadingH3Centered heading={<GetWGRole wgMemb={inv} />} />
                  </div>
                </>
              }
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center">
        <form onSubmit={handleSubmit}>
          <div>
            <button type="submit" className={BUTTON_SUBMIT_STYLE_1} >
              decline
            </button>
          </div>
        </form>
      </div>
            
      <div className="justify-center text-center pt-3">
        <Link to={`/workspace`} className={LINK_STYLE_3}>
          Cancel
        </Link>
      </div>
    </>
  )
}
