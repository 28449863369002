import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import { useDocumentA } from "../../hooks/useDocumentA";
import { useFirestore } from "../../hooks/useFirestore";
import { useFirestoreA } from "../../hooks/useFirestoreA";
import HeadingH3Centered from "../../components/HeadingH3Centered";
import GetWGRole from "../../components/GetWGRole";
import { BUTTON_SUBMIT_STYLE_1, LINK_STYLE_3 } from "../../components/Constants";

// accept
// 
// this means the invPaket for me
// user.uid = oUID in Prj.I should be taken away 
// and put in Prj.WG array

export default function WGInviteStep4() {
  const { user } = useAuthContext()
  const { jID } = useParams()

  const { error, document } = useDocument('USERS', user.uid)
  const { updateDocument, response } = useFirestore('USERS')
  // documentA is the project
  const { errorA, documentA } = useDocumentA('Projects', jID)
  const { updateDocumentA, responseA } = useFirestoreA('Projects')
  const navigate = useNavigate()

  if (error) {return <div className="error">{error}</div>}
  if (!document) {return <div className="loading">Loading ...</div>}
  if (errorA) {return <div className="error">{errorA}</div>}
  if (!documentA) {return <div className="loading">Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()
    const usersI = document.I
    const usersJ = document.J
    const projectsI = documentA.I
    const projectsKNM = documentA.KNM
    const projectsWG = documentA.WG

    // USERS - document
    // take info from usersI and add to usersJ
    // do this if this project is not already there

    const inviteInUsersI = usersI.find(e => e.jID === jID)

    if ((usersJ.filter(e => e.jID === jID).length === 0) && inviteInUsersI) {
      usersJ.push({
        jID, 
        q:'99',
        a:inviteInUsersI.a, // title of the project
        t:Date.now(), // time stamp created At
        iUID:inviteInUsersI.iUID, // of the person who pinged
        di:inviteInUsersI.di,
        ei:inviteInUsersI.ei,
        r9:inviteInUsersI.r9, // Owner
        r7:inviteInUsersI.r7, // Manager
        r5:inviteInUsersI.r5, // Admin
        r3:inviteInUsersI.r3, // Editor
        r1:inviteInUsersI.r1  // Reader
      })
    }

    // USERS - document
    // remove the entry from usersI
    const usersIInviteRemoved = usersI.filter(e => e.jID !== jID)

    // USERS - document
    // updates for USERS
    const updatesUSERS = { 
      J: usersJ, // J projects array
      I: usersIInviteRemoved, // I Invite array
    }

    // Projects

    // Add in WG
    // take info from projectsI and add to projectsWG
    // do this if this person is not already there
    // projectsWG: di do ei eo iUID oUID r1-r9 t

    const inviteInProjectsI = projectsI.find(e => e.oUID === user.uid)

    if ((projectsWG.filter(e => e.oUID === user.uid).length === 0) && inviteInProjectsI) {
      projectsWG.push({
        t:Date.now(), // time stamp created At
        iUID:inviteInProjectsI.iUID, // of the person who pinged
        oUID:inviteInProjectsI.oUID, // 
        di:inviteInProjectsI.di,
        do:inviteInProjectsI.do,
        ei:inviteInProjectsI.ei,
        eo:inviteInProjectsI.eo,
        r9:inviteInProjectsI.r9, // Owner
        r7:inviteInProjectsI.r7, // Manager
        r5:inviteInProjectsI.r5, // Admin
        r3:inviteInProjectsI.r3, // Editor
        r1:inviteInProjectsI.r1  // Reader
      })
    }
    // remove the entry from projectsI

    const projectsIInviteRemoved = projectsI.filter(e => e.oUID !== user.uid)

    projectsKNM.forEach(function (block) {
      block.wf.push({
        fa:true,
        v:true,
        uID: user.uid,
        d:user.displayName,
        e:user.email
      })
    })

    const updatesProj = { 
      I: projectsIInviteRemoved, // I Invite array
      WG: projectsWG,
      KNM: projectsKNM
    }

    // save updatesUSERS to my account in USERS collection
    await updateDocument(user.uid, updatesUSERS)

    // save updatesProj to proj
    await updateDocumentA(jID, updatesProj)

    if (!response.error && !responseA.error) {
      navigate('/workspace')
    }
    
  }

  return (
    <>
      <div className="py-5">
        <div className="flex justify-center">
          {document.I.length >0 && document.I.map(inv => (
            <div key={inv.jID} >
              {inv.jID === jID && !inv.y && !inv.n && 
                <>
                  <HeadingH3Centered heading={"You have been invited by"} />
                  <div className="font-mono font-bold">
                    <HeadingH3Centered heading={inv.di} />
                  </div>
                  <HeadingH3Centered heading={"to participate in the project"} />
                  <div className="flex justify-center gap-3 font-mono font-bold">
                    <HeadingH3Centered heading={documentA.a} />
                  </div>
                    <HeadingH3Centered heading={"as"} />
                  <div className="font-mono font-bold">
                    <HeadingH3Centered heading={<GetWGRole wgMemb={inv} />} />
                  </div>
                </>
              }
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center">
        <form onSubmit={handleSubmit}>
          <div>
            <button type="submit" className={BUTTON_SUBMIT_STYLE_1} >
              accept
            </button>
          </div>
        </form>
      </div>
            
      <div className="justify-center text-center pt-3">
        <Link to={`/workspace`} className={LINK_STYLE_3}>
          Cancel
        </Link>
      </div>
    </>
  )
}
