import { Link } from "react-router-dom";
import ListItemXS from "../../components/ListItemXS";
import { CardStackPlusIcon, DividerVerticalIcon, FilePlusIcon, Pencil2Icon, TrashIcon } from "@radix-ui/react-icons";
import { LINK_STYLE_3 } from "../../components/Constants";

export default function ZMenuhTmFiF({jID, jA, kID, N, pID}) {

  const HmiNodes = N.filter(nde => nde.h && !nde.m && !nde.i && nde.pID === pID)

  return (
    <>
    {HmiNodes && HmiNodes.length === 1 &&
      <div>
        <hr/>
        <div className='flex pt-1'>
          <ListItemXS />
          {HmiNodes[0].g === "Yx" && 
            <Link to={`/editnodeyx/${jID}/${jA}/${kID}/${HmiNodes[0].nID}`} 
              className={LINK_STYLE_3}
            >
              <Pencil2Icon />
            </Link>
          }
          {HmiNodes[0].g === "Yy" && 
            <Link to={`/editnodeyy/${jID}/${jA}/${kID}/${HmiNodes[0].nID}`} 
              className={LINK_STYLE_3}
            >
              <Pencil2Icon />
            </Link>
          }
          {HmiNodes[0].g === "Yb" && 
            <Link to={`/editnodeyb/${jID}/${jA}/${kID}/${HmiNodes[0].nID}`} 
              className={LINK_STYLE_3}
            >
              <Pencil2Icon />
            </Link>
          }
          <DividerVerticalIcon />
          <Link to={`/createnodehfmfif/${jID}/${jA}/${kID}/${HmiNodes[0].nID}`} 
            className={LINK_STYLE_3}
          >
            <FilePlusIcon />
          </Link>
          <DividerVerticalIcon />
          <Link to={`/createnodehfmfit/${jID}/${jA}/${kID}/${HmiNodes[0].nID}`} 
            className={LINK_STYLE_3}
          >
            <CardStackPlusIcon />
          </Link>
          <DividerVerticalIcon />
          <Link to={`/deletenodehtmfif/${jID}/${jA}/${kID}/${HmiNodes[0].nID}`} 
            className={LINK_STYLE_3}
          >
            <TrashIcon />
          </Link>
        </div>
      </div>
    }
    </>
  )
}