import { useState } from 'react'
import { useDocument } from '../../hooks/useDocument'
import { useFirestoreA } from '../../hooks/useFirestoreA'
import { v4 as uuidv4 } from 'uuid';
import { useFirestoreB } from '../../hooks/useFirestoreB';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { BUTTON_SUBMIT_STYLE_1, INPUT_STYLE_1, LINK_STYLE_1 } from '../../components/Constants';
import xForm from '../../hooks/xForm';

// initiate a project 
// there is no project type but only block type
export default function InitiateProject() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  const { setDocumentA, responseA } = useFirestoreA('Projects') 
  const { updateDocumentB, responseB } = useFirestoreB('USERS') 
  // The initiator of the project 
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const { xFormXaZ } = xForm()

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  // WG: [{di ei do eo iUID oUID r1-r9 t}]

  const handleSubmit = async (e) => {
    e.preventDefault()
    // unique project jID
    const jID = uuidv4()

    const payloadForProjectsDB = {
      jID:jID, // unique project id
      a:title, // title of the project fixed in latin letters
      i:user.uid, // initator or creator fixed forever
      I:[], // list of invitations sent
      KNM:[], // here later list of blocks in this project
      t:Date.now(), // time stamp created At
      WG:[{
        iUID:user.uid,
        oUID:user.uid, // the ping and pong are same in this case
        di:document.d, // display name fixed
        do:document.d, // display name fixed
        ei:document.e, // email of the creator
        eo:document.e, // email of the creator
        t:Date.now(), // time stamp created At
        r9:true, // Owner
        r7:true, // Manager
        r5:true, // Admin
        r3:true, // Editor
        r1:true  // Reader
      }]
    }

    // save at the Projects Collection
    await setDocumentA(jID, payloadForProjectsDB)

    // entry for USERS collection
    // under my projects arr J
    // jID q a g t r1-r9-T/F
    // iUID di ei who pinged me 
    // if I pinged me, I am the creator
    // oUID do eo are not needed, that is me

    let arr = document.J
    if (arr.filter(e => e.jID === jID).length === 0) {
      arr.push({
        jID, 
        q:'00', // my order of the project
        a:title, // title of the project fixed in latin letters
        t:Date.now(), // time stamp created At
        iUID:user.uid, // the ping and pong are same in this case
        di:document.d, // display name fixed
        ei:document.e, // email of the creator
        r9:true, // Owner
        r7:true, // Manager
        r5:true, // Admin
        r3:true, // Editor
        r1:true  // Reader
      })
    }

    const updatesB = { 
      J: arr, // J projects array
      qtj:document.qtj+1 // increment the actual number of projects
    }
    
    // Add it to the Projects list J of the Initiator
    await updateDocumentB(user.uid, updatesB)

    if (!responseB.error && !responseA.error) {
      navigate('/workspace')
    }
  }

  return (
    <>
      {(document.qtj < document.qtJ) && 
      <div>
        
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
            <div>{document.qtj}/{document.qtJ} Projects</div>
            <div>Initiate a new project</div>
          </div>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <div className='mt-2'>
                <div className='font-body font-medium tracking-wide leading-6 text-slate-900'
                    dangerouslySetInnerHTML={{ __html: xFormXaZ(title) }} />
              </div>
              <div className="mt-2">
                <input
                  id="title"
                  name="title"
                  type="text"
                  placeholder='Short title of the project'
                  autoComplete="title"
                  required
                  onChange={(e) => setTitle(e.target.value)} 
                  value={title}
                  className={INPUT_STYLE_1}
                />
              </div>
            </div>

            <div>
              <button type="submit" className={BUTTON_SUBMIT_STYLE_1} >
                Initiate
              </button>
            </div>
            <div>
              {error && <div>{error}</div>}
            </div>
          </form>

          <p className="text-center font-mono text-xs text-gray-500">
            <Link to="#" className={LINK_STYLE_1}>
              More information about projects
            </Link>
          </p>
        </div>
      </div>
      </div>
      }
    </>
  )
}
