import { Link } from 'react-router-dom'
import sortArray from 'sort-array'
import { LINK_STYLE_3, LINK_STYLE_4 } from '../../components/Constants'
import ListItemXS from '../../components/ListItemXS'
import xForm from '../../hooks/xForm'

export default function ZListMyProjects({me}) {
  const { xFormXaZ } = xForm()
  return (
    <>
    {/* J: list of my projects q: for sequencing*/}
    {/* jID: unique project ID */}
    {
      me.J.length > 0 && 
      <div className='px-2'>
        {
          me.J && sortArray(me.J, {by:"q", order:'asc'}).map(prj => (
            <div key={prj.jID}>
            {prj.iUID === me.uID && // this user is the initiator of this project
              <>
                <div className='flex pt-3'>
                  <ListItemXS />
                    <Link to={`/editprojectq/${prj.jID}`} className={LINK_STYLE_3}>
                      {prj.q}
                    </Link>
                </div>
                <div>
                  <Link to={`/projectoverview/${prj.jID}`} className={LINK_STYLE_4}>
                    <div className='font-body'
                      dangerouslySetInnerHTML={{ __html: xFormXaZ(prj.a) }} 
                    />
                  </Link>
                </div>
                <hr/>
              </>
            }
            </div>
          ))
        }
      </div>
    }
    </>
  )
}
