import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1 } from '../../components/Constants';
import { useNavigate, useParams } from 'react-router-dom';
import HeadingH3Centered from '../../components/HeadingH3Centered';

export default function DeleteNodehFmTFiF() {
  const { jID, jA, kID, nID } = useParams()
  // nID is the ID of the node to be deleted 
  const navigate = useNavigate()
  
  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/blockoverview/${jID}/${jA}/${kID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    navigate(`/deletenodehfmtfifyes/${jID}/${jA}/${kID}/${nID}`)
  }

  return (
    <>
      <div className="pt-5">
        <div className="font-mono font-bold">
          <HeadingH3Centered heading={"You are about to delete a node?"} />
        </div>
      </div>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
          
            <div className="flex gap-3">
              <button 
                type='button'
                onClick={handleCancel}
                className={BUTTON_CANCEL_STYLE_1}
              >
                Cancel
              </button>
              <button 
                type='submit'
                className={BUTTON_SUBMIT_STYLE_1}
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
