export default function HeadingH2Centered({heading}) {
  
  return (
    <>
      <div className="flex mt-1 justify-center text-center leading-6">
        <div className="text-xl tracking-tight text-abhi-red">
          {heading}  
        </div>
      </div>
    </>
  )
}