import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { Link, useNavigate, useParams } from "react-router-dom"
import { BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, INPUT_STYLE_1, LINK_STYLE_3, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Centered from "../../components/HeadingH3Centered"
import HeadingH2Centered from "../../components/HeadingH2Centered"

export default function EditProjectQ() {
  const { user } = useAuthContext()
  const { jID } = useParams()
  const { error, document } = useDocument('USERS', user.uid)
  const { updateDocument, response } = useFirestore('USERS') 
  const navigate = useNavigate()
  const [Q, setQ] = useState('')
  const [a, setA] = useState('')
  
  // J: Projects
  useEffect(() => {
    if (document && document.J){ 
      let result = document.J.filter(obj => {
        return obj.jID === jID
      })
      setQ(result[0].q)
      setA(result[0].a)
    }
  }, [jID, document])

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()

    const updatedJs = document.J.map( prj => {
      if (prj.jID === jID) {
        return {...prj, q:Q}
      } else {
        return {...prj}
      }
    })

    const updates = { 
      J: updatedJs
    }

    await updateDocument(user.uid, updates)
    if (!response.error) {
      navigate('/workspace')
    }
  }

  return (
    <>
      <div className="pt-5">
        <div className="font-mono font-bold">
          <HeadingH3Centered heading={"Edit Sequence of the project"} />
        </div>
        <div className="font-body font-bold">
          <HeadingH2Centered heading={a} />
        </div>
      </div>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <input 
              id="Q"
              type="text" 
              required
              onChange={(e) => setQ(e.target.value)}
              value={Q}
              className={INPUT_STYLE_1}
            />
            <button 
              type='submit'
              className={BUTTON_SUBMIT_STYLE_1}
            >
              Update
            </button>
          </form>
          <div className="justify-center text-center pt-3">
            <Link to={`/workspace/`} className={LINK_STYLE_3}>
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}