import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import HeadingH1Centered from '../../components/HeadingH1Centered'
import { BUTTON_SUBMIT_STYLE_1, INPUT_STYLE_1 } from '../../components/Constants'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error } = useLogin()
  
  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  } 

  return (
    <>
      <HeadingH1Centered heading={"Sign in"} />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" 
                className="block text-sm font-mono font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setEmail(e.target.value)} 
                  value={email}
                  className={INPUT_STYLE_1}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" 
                  className="block text-sm font-mono font-medium leading-6 text-gray-900">
                  Password
                </label>
                {/*<div className="text-sm">
                  <Link to="#" className="font-semibold font-mono text-abhi-link hover:text-abhi-link-hover">
                    Forgot password?
                  </Link>
                </div>*/}
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)} 
                  value={password}
                  className={INPUT_STYLE_1}
                />
              </div>
            </div>

            <div>
              <button type="submit" className={BUTTON_SUBMIT_STYLE_1}>
                Sign in
              </button>
            </div>
            <div>
              {error && <div>{error}</div>}
            </div>
          </form>

          <p className="text-center font-mono text-xs text-gray-500">
            Limited access for this test version!
          </p>

        </div>
      </div>
    </>
  )
}