
// given a pID i.e. nID of the primary node
// render all nodes that are its secondary

import sortArray from "sort-array"
import ZRenderNodeYx from "./ZRenderNodeYx"
import ZRenderNodeYy from "./ZRenderNodeYy"
import ZRenderNodeYb from "./ZRenderNodeYb"
import { Link } from "react-router-dom"
import { DividerVerticalIcon, DotFilledIcon, Pencil2Icon, PersonIcon, TrashIcon } from "@radix-ui/react-icons"
import { LINK_STYLE_3 } from "../../components/Constants"
import { useAuthContext } from "../../hooks/useAuthContext"
import TextMonoBoldXS from "../../components/TextMonoBoldXS"

// and belong to the right panel iT
export default function ZRenderhFmFiTNodes({jID, jA, kID, N, pID, showMenu}) {
  const { user } = useAuthContext() 
  const hmINodes = N.filter(nde => !nde.h && !nde.m && nde.i && 
    nde.pID === pID)
  
  return (
    <>
      {hmINodes && 
        sortArray(hmINodes, {by:"q", order:"asc"}).map(nd => (
          <div key={nd.nID}>
            {nd.uID === user.uid && 
            <>
              {nd.g === "Yx" && 
                <div>
                  {showMenu && 
                  <div className="flex">
                    <Link to={`/editnodeyx/${jID}/${jA}/${kID}/${nd.nID}`} 
                      className={LINK_STYLE_3}
                    >
                      <Pencil2Icon />
                    </Link>
                    <DividerVerticalIcon />
                    <Link to={`/deletenodehfmtfit/${jID}/${jA}/${kID}/${nd.nID}`} 
                      className={LINK_STYLE_3}
                    >
                      <TrashIcon />
                    </Link>
                    <PersonIcon />
                    <TextMonoBoldXS text={nd.d} />
                  </div>
                  }
                  <div className="flex leading-5 text-sm">
                    {!showMenu && 
                    <DotFilledIcon />}
                    <ZRenderNodeYx node={nd} />
                  </div>
                </div>
              }
              {nd.g === "Yy" && <ZRenderNodeYy node={nd} />}
              {nd.g === "Yb" && <ZRenderNodeYb node={nd} />}
            </>
            }
          </div>
        ))
      }
    </>
  )
}