import { DotFilledIcon, PersonIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import TextMonoBoldXS from "../../components/TextMonoBoldXS";
import ListItemXS from "../../components/ListItemXS";
import TextMonoXS from "../../components/TextMonoXS";
import TextMonoBoldSM from "../../components/TextMonoBoldSM";
import { LINK_STYLE_1 } from "../../components/Constants";
import TextMonoBoldSMRed from "../../components/TextMonoBoldSMRed";

export default function ZWGList({project}) {
  const { user } = useAuthContext()
  const [wgMember, setWgMember] = useState({})
  
  useEffect(() => {
    if (project && project.WG){ 
      let result = project.WG.filter(obj => {
          return obj.oUID === user.uid
      })
        setWgMember(result[0])
      }
    }, [project, user.uid])

  return (
    <>
      {/* J: list of my projects q: for sequencing*/}
      {/* project: is one such entry*/}
      {/* jID: unique project ID */} 
      <div className='flex px-2 pb-3 gap-1'>
        {project.WG && project.WG.length === 1 &&
          <TextMonoBoldXS text={project.WG.length+" member"}/>
        }
        {project.WG && project.WG.length > 1 &&
          <TextMonoBoldXS text={project.WG.length+" members"}/>
        }
      </div>

      {/** Better use oUID */} 
      <div className="px-2">
        <div className='flex pb-1'>
          <ListItemXS />
          <TextMonoXS text={"Owners"} />
        </div>
        <div className="flex gap-1 pb-3">
        {
          project.WG && project.WG.map(p => (
            <div key={p.oUID} className='flex pb-1 gap-1'>
              {
                p.r9 && p.do && p.iUID === p.oUID &&
                <div className='flex gap-1'>
                  <PersonIcon />
                  <TextMonoBoldSM text={p.do} />
                </div>
              }
              
              {
                p.r9 && p.do && p.iUID !== p.oUID &&
                <div className='flex'>
                  <DotFilledIcon />
                  <TextMonoBoldSM text={p.do} />
                </div>
              }
            </div>
          ))
        }
        </div>

        <div className='flex pb-1'>
          <ListItemXS />
          <TextMonoXS text={"Managers"} />
        </div>
        <div className="flex gap-1 pb-3">
        {
          project.WG && project.WG.map(p => (
            <div key={p.oUID} className='flex pb-1 gap-1'>
              {
                !p.r9 && p.r7 && p.do && p.iUID === p.oUID &&
                <div className='flex gap-1'>
                  <PersonIcon />
                    {wgMember.r9 && 
                      <Link to={`/wgchangerole/${project.jID}/${p.oUID}`} className={LINK_STYLE_1}>
                        <TextMonoBoldSMRed text={p.do} />
                      </Link>
                    }
                    {!wgMember.r9 &&
                      <TextMonoBoldSM text={p.do} />
                    }
                </div>
              }
              
              {
                !p.r9 && p.r7 && p.do && p.iUID !== p.oUID &&
                <div className='flex'>
                  <DotFilledIcon />
                    {wgMember.r9 && 
                      <Link to={`/wgchangerole/${project.jID}/${p.oUID}`}className={LINK_STYLE_1}>
                        <TextMonoBoldSMRed text={p.do} />
                      </Link>
                    }
                    {!wgMember.r9 &&
                      <TextMonoBoldSM text={p.do} />
                    }
                </div>
              }
            </div>
          ))
        }
        </div>

        <div className='flex pb-1'>
          <ListItemXS />
          <TextMonoXS text={"Admins"} />
        </div>
        <div className="flex gap-1 pb-3">
        {
          project.WG && project.WG.map(p => (
            <div key={p.oUID} className='flex pb-1 gap-1'>
              {
                !p.r9 && !p.r7 && p.r5 && p.do && p.iUID === p.oUID &&
                <div className='flex gap-1'>
                  <PersonIcon />
                    {wgMember.r7 && 
                      <Link to={`/wgchangerole/${project.jID}/${p.oUID}`} className={LINK_STYLE_1}>
                        <TextMonoBoldSMRed text={p.do} />
                      </Link>
                    }
                    {!wgMember.r7 &&
                      <TextMonoBoldSM text={p.do} />
                    }
                </div>
              }
              
              {
                !p.r9 && !p.r7 && p.r5 && p.do && p.iUID !== p.oUID &&
                <div className='flex'>
                  <DotFilledIcon />
                    {wgMember.r7 && 
                      <Link to={`/wgchangerole/${project.jID}/${p.oUID}`} className={LINK_STYLE_1}>
                        <TextMonoBoldSMRed text={p.do} />
                      </Link>
                    }
                    {!wgMember.r7 &&
                      <TextMonoBoldSM text={p.do} />
                    }
                </div>
              }
            </div>
          ))
        }
        </div>

        <div className='flex pb-1'>
          <ListItemXS />
          <TextMonoXS text={"Editors"} />
        </div>
        <div className="flex gap-1 pb-3">
        {
          project.WG && project.WG.map(p => (
            <div key={p.oUID} className='flex pb-1 gap-1'>
              {
                !p.r9 && !p.r7 && !p.r5 && p.r3 && p.do && p.iUID === p.oUID &&
                <div className='flex gap-1'>
                  <PersonIcon />
                    {wgMember.r7 && 
                      <Link to={`/wgchangerole/${project.jID}/${p.oUID}`} className={LINK_STYLE_1}>
                        <TextMonoBoldSMRed text={p.do} />
                      </Link>
                    }
                    {!wgMember.r7 &&
                      <TextMonoBoldSM text={p.do} />
                    }
                </div>
              }
              
              {
                !p.r9 && !p.r7 && !p.r5 && p.r3 && p.do && p.iUID !== p.oUID &&
                <div className='flex'>
                  <DotFilledIcon />
                    {wgMember.r7 && 
                      <Link to={`/wgchangerole/${project.jID}/${p.oUID}`} className={LINK_STYLE_1}>
                        <TextMonoBoldSMRed text={p.do} />
                      </Link>
                    }
                    {!wgMember.r7 &&
                      <TextMonoBoldSM text={p.do} />
                    }
                </div>
              }
            </div>
          ))
        }
        </div>

        <div className='flex pb-1'>
          <ListItemXS />
          <TextMonoXS text={"Readers"} />
        </div>
        <div className="flex gap-1 pb-3">
        {
          project.WG && project.WG.map(p => (
            <div key={p.oUID} className='flex pb-1 gap-1'>
              {
                !p.r9 && !p.r7 && !p.r5 && !p.r3 && p.r1 && p.do && p.iUID === p.oUID &&
                <div className='flex gap-1'>
                  <PersonIcon />
                    {wgMember.r7 && 
                      <Link to={`/wgchangerole/${project.jID}/${p.oUID}`} className={LINK_STYLE_1}>
                        <TextMonoBoldSMRed text={p.do} />
                      </Link>
                    }
                    {!wgMember.r7 &&
                      <TextMonoBoldSM text={p.do} />
                    }
                </div>
              }
              
              {
                !p.r9 && !p.r7 && !p.r5 && !p.r3 && p.r1 && p.do && p.iUID !== p.oUID &&
                <div className='flex'>
                  <DotFilledIcon />
                    {wgMember.r7 && 
                      <Link to={`/wgchangerole/${project.jID}/${p.oUID}`} className={LINK_STYLE_1}>
                        <TextMonoBoldSMRed text={p.do} />
                      </Link>
                    }
                    {!wgMember.r7 &&
                      <TextMonoBoldSM text={p.do} />
                    }
                </div>
              }
            </div>
          ))
        }
        </div>
      </div>
      
    </>
  )
}
