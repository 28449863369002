import { PlusIcon } from "@radix-ui/react-icons"
import { ERROR_STYLE_1, LINK_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Mono from "../../components/HeadingH3Mono"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from "../../hooks/useDocument"
import ZListMyParticipation from "./ZListMyParticipation"
import ZListMyProjects from "./ZListMyProjects"
import { Link } from "react-router-dom"
import ZInviteListPong from "./ZInviteListPong"
import ZInviteListPongDeclined from "./ZInviteListPongDeclined"

export default function Workspace() {

  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  return (
    <>
      <div className='grid sm:grid-cols-2 gap-3 px-4 sm:px-8 md:px-12'>

        <div>
          <HeadingH3Mono heading={"My Participation"} />
          <ZListMyParticipation me={document} />
          <ZInviteListPong inviteList={document.I} />
          <ZInviteListPongDeclined inviteList={document.I} />
        </div>

        <div>
          <div className="flex">
            <HeadingH3Mono heading={"My Projects"} />
            {
              (document.qtj < document.qtJ) && 
              <div className="pt-3">
                <Link to="/initiateproject" className={LINK_STYLE_1}>
                  <PlusIcon />
                </Link>
              </div>
            }
          </div>
          <ZListMyProjects me={document} />
        </div>
      </div>
    </>
  )
}