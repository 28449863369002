import TextMonoXS from "../../components/TextMonoXS"
import xForm from "../../hooks/xForm"

export default function ZRenderNodeYb({node}) {
  const { xFormXaZ } = xForm()
  return (
    <>
      <div className="flex gap-1">
        <div className='text-left text-xs font-mono font-bold tracking-tight text-gray-900'
          dangerouslySetInnerHTML={{ __html: xFormXaZ(node.a) }} 
          />
        <TextMonoXS text={"("+node.j+")"} />
      </div>
      <div className='font-body text-sm'
        dangerouslySetInnerHTML={{ __html: xFormXaZ(node.tl) }} 
      />
    </>
  )
}