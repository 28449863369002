import { useState } from 'react'
import { useDocument } from '../../hooks/useDocument'
import { useFirestore } from '../../hooks/useFirestore';
import { useFirestoreA } from '../../hooks/useFirestoreA'
import { v4 as uuidv4 } from 'uuid';

import { useAuthContext } from '../../hooks/useAuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDocumentB } from '../../hooks/useDocumentB';
import { useFirestoreB } from '../../hooks/useFirestoreB';
import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, INPUT_STYLE_1, LINK_STYLE_1, LOADING_STYLE_1 } from '../../components/Constants';
import xForm from '../../hooks/xForm';

// create a knm block of type kType
// a block is created ONLY within a project by those who have r9/owner role
// the block is visible always to all members of WG in their respective roles
// a block can be made visible by r5 to any colleage/or even any registered user temporarily

// when a block is created, it is
// 1. created and placed at KNM Collection
// 2. registered at Projects Collection
// 3. registered at USERS Collection

// 1. KNM Collection
// a c t N
// a : display title almost fixed
// c : creator uID FIXED forever
// t : timestamp
// N : Nodes list

export default function CreateBlock() {
  // this person is going to be the creator/owner
  const { user } = useAuthContext() 
  // a block is approachable only through a project
  const { jID } = useParams() 
  // it will be located in this project
  const { error, document } = useDocument('Projects', jID) 
  // it is a separate doc in KNM collection
  const { setDocument, response } = useFirestore('KNM') 
  // we need to update the project values
  const { updateDocumentA, responseA } = useFirestoreA('Projects') 
  // we need users info
  const { errorB, documentB } = useDocumentB('USERS', user.uid)
  // we need to register this block to the user's info
  const { updateDocumentB, responseB } = useFirestoreB('USERS') 
  const navigate = useNavigate()
  // only two initial parameters are set.
  // title, which should not be changed later as it is registered in different registers
  const [title, setTitle] = useState('')
  // sequence within the project, which can be changed later
  const [q, setQ] = useState('00')
  const { xFormXaZ } = xForm()

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  if (errorB) {return <div className={ERROR_STYLE_1}>{errorB}</div>}
  if (!documentB) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/projectoverview/${jID}`)
  }

  // 1. for KNM collection
  const handleSubmit = async (e) => {
    e.preventDefault()
    // we generate a random key
    const kID = uuidv4()
    const payloadForKNM = {
      kID, // kID of this block
      jID, // jID of the project it is part of
      a:title, // title of the block for organisational purpose
      c:user.uid, // creator fixed forever, first owner, remains forever
      t:Date.now(), // time stamp created At
      N:[], // main axis, nodes 
    }
    
    // save the payloadForKNM in the KNM Collection
    await setDocument(kID, payloadForKNM)

    // 2. register at Projects Collection
    // kID, a, q
    // added to this q for sequencing
    // t for timestamp

    // register this block in the Projects collection
    // document is the project doc corresp to jID
    let arrKNM = document.KNM // the KNM arr in prj doc
    let wfPayload = document.WG.flatMap(member => [{
      uID: member.oUID, // which contributor is working
      d:member.do, // displayName
      e:member.eo, // email
      v:true, // Visibility for this user. Default visible.
      //fa:true // The functionalities are encoded in params like fa fb fc etc T/F
    }])
    // if this block is not already there (do we need this?)
    if (arrKNM.filter(e => e.kID === kID).length === 0) {
      arrKNM.push({
        kID, // block unique ID
        a:title, // title of this block for organizational purposes
        q, // seq of the blocks within project, there is one seq for all WG members
        wf: wfPayload
      })
    }

    const payloadProjectsColl = { 
      KNM: arrKNM // KNM array in the Projects collection 
    }
    
    // Add it to the Projects collection
    await updateDocumentA(jID, payloadProjectsColl)

    // 3. register at USERS Collection
    
    const payloadUsersColl = {
      qtk:documentB.qtk+1 // increment the actual block nos
      // we use this for quota
    }
    // Update the USERS qtk value
    await updateDocumentB(user.uid, payloadUsersColl)
    // By default all users in project WG have access to this block
    // In case you want to hide it or show only to few, go to 
    // Manage block and change Projects.KNM.wf.v

    if (!response.error && !responseA.error && !responseB.error) {
      navigate(`/projectoverview/${jID}`)
    }
  }

  return (
    <>
      {/** if quota does not exceed */}
      {(documentB.qtk < documentB.qtK) && 
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
            Add a new block
          </div>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <div className='mt-2'>
                <div className='font-body font-medium tracking-wide leading-6'
                  dangerouslySetInnerHTML={{ __html: xFormXaZ(title) }} />
              </div>
              <div className="mt-2">
                <input
                  id="title"
                  name="title"
                  type="text"
                  placeholder='Short title of the block'
                  autoComplete="title"
                  required
                  onChange={(e) => setTitle(e.target.value)} 
                  value={title}
                  className={INPUT_STYLE_1}
                />
              </div>
              <div className="mt-2">
                <input
                  id="q"
                  name="q"
                  type="text"
                  placeholder='Sequence of the block'
                  autoComplete="q"
                  onChange={(e) => setQ(e.target.value)} 
                  value={q}
                  className={INPUT_STYLE_1}
                />
              </div>
            </div>

                <div className="flex gap-3">
                  <button 
                    type='button'
                    onClick={handleCancel
                    }
                    className={BUTTON_CANCEL_STYLE_1}
                  >
                    Cancel
                  </button>
                  <button 
                    type='submit'
                    className={BUTTON_SUBMIT_STYLE_1}
                  >
                    Update
                  </button>
                </div>

            <div>
              {error && <div>{error}</div>}
            </div>
          </form>

          <p className="text-center font-mono text-xs text-gray-500">
            <Link to="#" className={LINK_STYLE_1.concat(" leading-6")}>
              More information about blocks
            </Link>
          </p>
        </div>
      </div>
      }
      {/** if quota over */}
      {(documentB.qtk === documentB.qtK) && 
      <div>
        <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
          <div><span className="text-abhi-link">{documentB.qtk}</span>/{documentB.qtK} Blocks</div>
          <Link to="/upgrade" className={LINK_STYLE_1}>
            Upgrade
          </Link>
        </div>
      </div>
      }
    </>
  )
}
