import { PlusIcon } from "@radix-ui/react-icons"
import { ERROR_STYLE_1, LINK_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Mono from "../../components/HeadingH3Mono"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from "../../hooks/useDocument"
import ZColleagueStatus from "../colleagues/ZColleagueStatus"
import ZMyProfile from "./ZMyProfile"
import ZMyQuota from "./ZMyQuota"
import { Link } from "react-router-dom"

export default function Profile() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}

  return (
    <>
      <div className='grid sm:grid-cols-4 gap-3'>
        <div className="px-4 sm:px-12 sm:col-span-2 pb-5">
          <div className="flex">
            <HeadingH3Mono heading={"My Colleagues"} />
            {
              document.C.filter(c => {return c.i && c.y}).length < document.qtCi && // quota ping
              document.C.length < document.qtC && // quota C strength
              <div className="pt-3">
                <Link to="/collaborationreq1" className={LINK_STYLE_1}>
                  <PlusIcon />
                </Link>
              </div>
            }
          </div>
          
          {document.C.length > 0 && document.C.map(doc => (
            <div key={doc.o}>
                <ZColleagueStatus contact={doc} />
            </div>
            ))
          }
        </div>
        
        <div className="px-4 sm:col-span-1">
          <HeadingH3Mono heading={"My Profile"} />
          <ZMyProfile me={document} />
        </div>

        <div className="px-4 sm:col-span-1">
          <HeadingH3Mono heading={"My Quota"} />
          <ZMyQuota me={document} />
        </div>
        
      </div>
    </>
  )
}