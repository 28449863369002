import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import { Link, useParams } from "react-router-dom"
import { useDocumentA } from "../../hooks/useDocumentA"
import { ERROR_STYLE_1, LINK_STYLE_1, LOADING_STYLE_1 } from "../../components/Constants"
import HeadingH3Mono from "../../components/HeadingH3Mono"
import ListItemXS from "../../components/ListItemXS"
import TextMonoXS from "../../components/TextMonoXS"
import TextMonoBoldSMRed from "../../components/TextMonoBoldSMRed"

export default function WGInviteStep1() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  const { jID } = useParams()
  const { errorA, documentA } = useDocumentA('Projects', jID)

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  if (errorA) {return <div className={ERROR_STYLE_1}>{errorA}</div>}
  if (!documentA) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  
  return (
    <>
      {/** document.C are my contacts/colleagues */}
      {/** documentA.WG check those already invited for this project */}
      {/** they should be ignored */} 
      <div className='grid sm:grid-cols-3 py-3'>
        <div className="px-4 sm:px-12 sm:col-span-2 pb-5">
          <div className="flex justify-start">
            <HeadingH3Mono heading={"WorkGroup"} />
          </div>

        </div>

        <div className="px-4 sm:col-span-1">
          <div className="flex justify-start">
            <HeadingH3Mono heading={"Colleagues"} />
          </div>

          {document.C && document.C.map(contact => (
            <div key={contact.t}>
              {
                contact.y && ((documentA.WG && 
                (documentA.WG.filter(e => e.oUID === contact.o).length === 0) && 
                (documentA.WG.filter(e => e.iUID === contact.o).length === 0)) &&
                  (documentA.I && 
                  (documentA.I.filter(e => e.oUID === contact.o).length === 0) && 
                  (documentA.I.filter(e => e.iUID === contact.o).length === 0))) && 
                  <>
                    <div className="flex justify-left pt-1 px-1">
                      <ListItemXS />
                      <Link 
                        to={`/wginvitestep2/${jID}/${contact.o}`} className={LINK_STYLE_1}> 
                        <TextMonoBoldSMRed text={contact.d} />
                      </Link>
                    </div>
                    <div className="pb-1 px-3">
                      <TextMonoXS text={contact.e} />
                    </div>
                    <hr/>
                  </>
              }
            </div>
          ))
          }
        </div>
      </div>
    </>
  )
}
