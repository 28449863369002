import { Link } from "react-router-dom";
import ListItemXS from "../../components/ListItemXS";
import { CardStackPlusIcon, DividerVerticalIcon, FilePlusIcon, Pencil2Icon, PlusIcon, ReaderIcon, TrashIcon } from "@radix-ui/react-icons";
import { LINK_STYLE_3 } from "../../components/Constants";

// 
export default function ZMenuhTmTiF({jID, jA, kID, N, node}) {

  return (
    <>
      <div>
        <hr/>
        <div className='flex pt-1'>
          <div className='flex'>
            <ListItemXS />
            <Link to={`/editnodehtmtifq/${jID}/${jA}/${kID}/${node.nID}`} 
              className={LINK_STYLE_3}
            >
              {node.q}
            </Link>
          </div>
          <DividerVerticalIcon />
          <Link to={`/rendernodepage/${jID}/${jA}/${kID}/${node.nID}`} 
            className={LINK_STYLE_3}
          >
            <ReaderIcon />
          </Link>
          <DividerVerticalIcon />
          {node.g === "Yx" && 
            <Link to={`/editnodeyx/${jID}/${jA}/${kID}/${node.nID}`} 
              className={LINK_STYLE_3}
            >
              <Pencil2Icon />
            </Link>
          }
          {node.g === "Yy" && 
            <Link to={`/editnodeyy/${jID}/${jA}/${kID}/${node.nID}`} 
              className={LINK_STYLE_3}
            >
              <Pencil2Icon />
            </Link>
          }
          {node.g === "Yb" && <div>Edit Yb</div>}
        
          <DividerVerticalIcon />
          <Link to={`/createnodehfmtif/${jID}/${jA}/${kID}/${node.nID}`} 
            className={LINK_STYLE_3}
          >
            <FilePlusIcon />
          </Link>
          <DividerVerticalIcon />
          <Link to={`/createnodehfmtit/${jID}/${jA}/${kID}/${node.nID}`} 
            className={LINK_STYLE_3}
          >
            <CardStackPlusIcon />
          </Link>

          {N.filter(nd => nd.h && nd.pID === node.nID).length === 0 &&  
          // only when there is no mF node with hT
          <div className="flex">
            <DividerVerticalIcon />
            <Link to={`/createnodehtmfif/${jID}/${jA}/${kID}/${node.nID}`} 
            className={LINK_STYLE_3}
            >
              <PlusIcon />
            </Link>
          </div>
          }

          <DividerVerticalIcon />
          <Link to={`/deletenodehtmtif/${jID}/${jA}/${kID}/${node.nID}`} 
            className={LINK_STYLE_3}
          >
            <TrashIcon />
          </Link>
        </div>
      </div>
    </>
  )
}