import HeadingH1Centered from "../../components/HeadingH1Centered";

export default function Home() {
  const date1 = new Date()
  console.log(date1)
  return (
    <>
      <HeadingH1Centered heading={"Home"} />
      <p className="text-center font-mono text-xs text-gray-500">
        Coming soon!
      </p>
    </>
  )
}
