import { CheckIcon, CopyIcon } from '@radix-ui/react-icons'
import { useAuthContext } from '../../hooks/useAuthContext'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'
import { useDocument } from '../../hooks/useDocument'
import { useParams } from 'react-router-dom'
import { ERROR_STYLE_1, LOADING_STYLE_1 } from '../../components/Constants'
import TextBodyBoldRed from '../../components/TextBodyBoldRed'
import NavbarProjectBlock from '../../components/NavbarProjectBlock'
import xBytes from '../../plugs/xBytes'
import TextMonoBoldSM from '../../components/TextMonoBoldSM'

export default function GetBlockJSON() {
  const { user } = useAuthContext()
  const { jID, jA, kID } = useParams()
  const { error, document } = useDocument("KNM", kID)
  const [isCopied, handleCopy] = useCopyToClipboard(3000)

  const { formatBytes } = xBytes()

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  return (
    <>
      <NavbarProjectBlock jID={jID} jA={jA} kID={kID} kA={document.a} /> 
      {
        document.c === user.uid && // if the present user is also the creator of this block
        // later include here the owners as well. Need meInWG info for this
        <>
          <div className='grid sm:grid-cols-1 py-3'>
            <div className="px-4 sm:px-12 sm:col-span-1 pb-5">
              <div className=''>
                {error && <p className=''>{error}</p>}
                <TextMonoBoldSM text={formatBytes(JSON.stringify(document, null, 2).length)} />
                <span onClick={() => handleCopy(JSON.stringify(document, null, 2))}>
                  { isCopied ? 
                    <div className='flex text-abhi-red'>
                      <CheckIcon />
                      <TextBodyBoldRed text={"copied"} />
                    </div> : 
                    <div className='flex gap-1 text-abhi-red'>
                      <CopyIcon />
                      <TextBodyBoldRed text={"copy"} />
                    </div>
                  }
                </span>
                  <pre>{JSON.stringify(document, null, 2)}</pre>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}