import TextMonoXS from "../../components/TextMonoXS";

export default function ZMyParticipationRole({prj}) {

  return (
    <>
    {prj.r9 && <TextMonoXS text={"Owner"}/>}
    {!prj.r9 && prj.r7 && <TextMonoXS text={"Manager"}/>}
    {!prj.r9 && !prj.r7 && prj.r5 && <TextMonoXS text={"Admin"}/>}
    {!prj.r9 && !prj.r7 && !prj.r5 && prj.r3 && <TextMonoXS text={"Editor"}/>}
    {!prj.r9 && !prj.r7 && !prj.r5 && !prj.r3 && prj.r1 && <TextMonoXS text={"Reader"}/>}  
    </>
  )
}
