import { useState } from 'react';
import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, INPUT_STYLE_1 } from '../../components/Constants';
import { useNavigate, useParams } from 'react-router-dom';
import HeadingH3Centered from '../../components/HeadingH3Centered';


export default function ImportNodehTmTiF1() {
  const { jID, jA, kID } = useParams()
  // where the node is to be added
  const navigate = useNavigate()

  const [impJKNID, setImpJKNID] = useState('')

  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/blockoverview/${jID}/${jA}/${kID}`)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (impJKNID){
      const impJKNIDArr = impJKNID.trim().split("/")
      if (impJKNIDArr.length === 2){
        navigate(`/importnodehtmtif2/${jID}/${jA}/${kID}/${impJKNIDArr[0]}/${impJKNIDArr[1]}`)
      } else {
        navigate(`/blockoverview/${jID}/${jA}/${kID}`)
      }
    }
    // first get the impJKNID
  
    // then get the array of the corresponding nodes

    // push this to the N arr of the present block
  }

  return (
    <>
      <div className="pt-5">
        <div className="font-mono font-bold">
          <HeadingH3Centered heading={"Submit the IDs of the node to be imported"} />
        </div>
      </div>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
          
            <div className="mt-2">
              <input 
                id="impJKNID"
                type="text" 
                required
                placeholder='kID/nID'
                onChange={(e) => setImpJKNID(e.target.value)}
                value={impJKNID}
                className={INPUT_STYLE_1}
              />
            </div>
        
            <div className="flex gap-3">
              <button 
                type='button'
                onClick={handleCancel}
                className={BUTTON_CANCEL_STYLE_1}
              >
                Cancel
              </button>
              <button 
                type='submit'
                className={BUTTON_SUBMIT_STYLE_1}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
