import { Link } from 'react-router-dom'
import { LINK_STYLE_1 } from './Constants'
import xForm from '../hooks/xForm'

export default function NavbarProject({jID, jA}) {
  const { xFormXaZ } = xForm()

  return (
    <div className="flex font-mono font-bold gap-1 text-xs ml-4 py-1">
      <Link to={`/projectoverview/${jID}`} className={LINK_STYLE_1}>
        <div className=''
          dangerouslySetInnerHTML={{ __html: xFormXaZ(jA) }} 
        />
      </Link>
    </div>
  )
}