import { BUTTON_CANCEL_STYLE_1, BUTTON_SUBMIT_STYLE_1, ERROR_STYLE_1, LOADING_STYLE_1 } from '../../components/Constants';
import { useDocument } from '../../hooks/useDocument'
import { useNavigate, useParams } from 'react-router-dom';
import { useFirestore } from '../../hooks/useFirestore';
import HeadingH3Centered from '../../components/HeadingH3Centered';

// nID unique node ID also timestamp Date.now()
// this will delete both hTmF and all the children hFmF-iF/iT nodes
// only owners and managers r9/r7 should be allowed to do this
export default function DeleteNodehTmFiFYes() {
  const { jID, jA, kID, nID } = useParams()
  // nID is the ID of the node to be deleted
  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 
  const navigate = useNavigate()

  if (error) {return <div className={ERROR_STYLE_1}>{error}</div>}
  if (!document) {return <div className={LOADING_STYLE_1}>Loading ...</div>}
  
  const handleCancel = (e) => {
    e.preventDefault()
    navigate(`/blockoverview/${jID}/${jA}/${kID}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    const updates = { 
      N: document.N
      // (!nd.h || nd.m || nd.nID !== nID)
      // !(nd.h && !nd.m && nd.nID === nID)
      .filter(nd => !nd.h || nd.m || nd.nID !== nID)
      // !(!nd.h && !nd.m && nd.pID === nID) for both iF and iT
      // (nd.h || nd.m || nd.pID !== nID) for both iF and iT
        .filter(nd => nd.h || nd.m || nd.pID !== nID) 
    }
    // update the block
    await updateDocument(kID, updates)

    if (!response.error) {
      navigate(`/blockoverview/${jID}/${jA}/${kID}`)
    }
  }

  return (
    <>
      <div className="pt-5">
        <div className="font-mono font-bold">
          <HeadingH3Centered heading={"Delete a node h:t m:f i:false"} />
        </div>
      </div>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
          
            <div className="flex gap-3">
              <button 
                type='button'
                onClick={handleCancel}
                className={BUTTON_CANCEL_STYLE_1}
              >
                Cancel
              </button>
              <button 
                type='submit'
                className={BUTTON_SUBMIT_STYLE_1}
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
